import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { EmailValidator } from '../../shared/app-forms/validators/email.validator';
import { AppConfigService } from '../../shared/config/app-config.service';
import { RequestParameter } from '../../shared/common/request-parameter.model';
import { TranslationStorageService } from '../../shared/storage/translation/translation-storage.service';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import { RequestAccessService } from '../../shared/authentication/sign-up.service';
import { Subscription } from 'rxjs';
import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, DoCheck, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookiesService } from 'app/shared/cookies/cookies.service';
import { FeatureConst } from '../../shared/feature/feature.consts';
import { FeatureService } from '../../shared/feature/feature.service';
import { environment } from 'environments/environment';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { SignUpLanguage } from '../../shared/models/sign-up-language.model';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class SignUpComponent implements OnDestroy, OnInit, DoCheck, AfterViewInit {

  // Form values & state
  public form: FormGroup;
  public isLoading: boolean;
  public availableLanguages: SignUpLanguage[] = [];
  public submitted = false;
  public selectedLanguage: SignUpLanguage;
  public readonly GTM_EVENTS = GTM_EVENTS;
  public captchaCookieConsentStatus: string;
  private interval;

  /*
   Route anim variables
   */
  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(
    private appConfig: AppConfigService,
    private formBuilder: FormBuilder,
    private router: Router,
    private navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef,
    private requestAccessService: RequestAccessService,
    private translationStorage: TranslationStorageService,
    private appStorageService: AppStorageService,
    private configurationService: ConfigurationService,
    private translationService: TranslationService,
    private cookiesService: CookiesService,
    private gtmService: GtmService,
    private changeDetectorRef: ChangeDetectorRef,
    private featureService: FeatureService) {

    // Init transition animation logic
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

    // Create form for this page
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, EmailValidator.simple]],
      company: ['', Validators.required],
      license: ['', [Validators.required]],
      legal: ['', Validators.requiredTrue],
      recaptcha: ['', Validators.required]
    });

    if (this.shouldDisplayRole()) {
      this.form.addControl('role', this.formBuilder.control('driver'));
    }
  }

  get role(): string {
    return this.form.get('role') ? this.form.get('role').value : null;
  }

  onRoleChange() {

    if (this.form.contains('role') && this.form.get('role').value === 'driver') {
      this.form.controls['license'].setValidators([Validators.required]);
      this.form.controls['license'].updateValueAndValidity();
    } else {
      this.form.controls['license'].setValidators(null);
      this.form.controls['license'].updateValueAndValidity();
    }
  }

  ngOnInit() {
        // Create form for this page
          this.form = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, EmailValidator.simple]],
            company: ['', Validators.required],
            license: ['', [Validators.required]],
            legal: ['', Validators.requiredTrue],
            recaptcha: ['', Validators.required]
          });
        if (this.shouldDisplayRole()) {
          this.form.addControl('role', this.formBuilder.control('driver'));
        }
    this.configurationService.getAvailableLanguages()
      .subscribe(
        languages => {
          const selectedLanguage = this.appStorageService.get('lang');
          if (selectedLanguage) {
            languages.forEach((language) => {
              if (language.codeLanguage.toLowerCase() === selectedLanguage) {
                this.selectedLanguage = language;
              }
            });
          }
          this.availableLanguages = languages;
        },
        error => {
          console.error('An error has occured: ', error);
        }
      );
  }

  shouldDisplayRole(): boolean {
    return !this.appConfig.isMobile &&
      this.appConfig.config.roles.driver &&
      this.appConfig.config.roles.manager;
  }

  onSubmit() {
    if (this.form.valid) {
      this.isLoading = true;
      const params: RequestParameter[] = [];
      const selectedCountry = this.appStorageService.countrySelected;
      params.push(new RequestParameter('countryCode', selectedCountry));
      params.push(new RequestParameter('email', this.form.get('email').value));
      params.push(new RequestParameter('companyName', this.form.get('company').value));
      params.push(new RequestParameter('culture', this.translationStorage.lang.toString()));
      params.push(new RequestParameter('subsidiary', selectedCountry));
      params.push(new RequestParameter('recaptcha', this.form.get('recaptcha').value));
        params.push(new RequestParameter('name', this.form.get('name').value));
        params.push(new RequestParameter('plate', this.form.get('license').value));
        params.push(new RequestParameter('role',
          this.form.get('role').value === 'fleet' ? 'fleetmanager' : this.form.get('role').value));

      this.requestAccessService.tryRequest(params, this.role)
        .then((feedback: any) => {
          if (feedback.hasOwnProperty('error') && feedback.error !== null) {
            this.handleError(feedback.error);
          } else if (feedback.state && feedback.state !== 'UserCreated') {
            this.handleError(feedback.error);
          } else {
            this.isLoading = false;
            this.navigationDirection.direction = 'forward';
            this.gtmService.sendEvent(GTM_EVENTS.signUpUser, 'user created');
            this.router.navigate(['/start/sign-up-confirmation']);
          }
        })
        .catch(e => {
          this.handleError(e);
          this.isLoading = false;
        });

    }

  }

  handleError(error) {
    this.router.navigate(['/start/sign-up-error']);
  }

  public changeLanguage(language: SignUpLanguage) {
    this.translationService.language = language.codeLanguage;
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
    window.sessionStorage.removeItem('captcha_cookie_consent_status');
    clearInterval(this.interval);
  }

  onBackClick() {
    this.navigationDirection.direction = 'backward';
    this.router.navigate(['/start/login']);
  }

  languageFlagName(codeLanguage) {
    const shortenedCodeLanguage = codeLanguage.substr(3, 2);
    return shortenedCodeLanguage === 'en' ? 'gb' : shortenedCodeLanguage;
  }

  linkToTermesOfUse() {
    return `${environment.publicDocumentsBaseUrl}/${this.appStorageService.countrySelected}/disclaimer-${this.translationService.language}.pdf`;
  }

  linkToCookiesPolicy() {
    return this.cookiesService.getCookiesPolicyDocumentUrl(this.translationService.language);
  }

  ngDoCheck() {
    this.captchaCookieConsentStatus = window.sessionStorage.getItem('captcha_cookie_consent_status');
    if (this.captchaCookieConsentStatus === 'agree') {
      clearInterval(this.interval);
    }
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.interval = setInterval(() => { }, 1000);
  }

}
