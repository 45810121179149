﻿import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { DriverService } from '../../../driver/shared/driver.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopinService } from '../../ui/popin.service';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { GtmService } from '../../gtm/gtm.service';
import { GTM_EVENTS } from '../../gtm/gtm-events.const';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';
import { sameAsValidator } from 'app/shared/app-forms/validators/same-as.validator';
import { passwordUserNameValidator } from 'app/shared/app-forms/validators/password-username.validator';
import { UserService } from 'app/shared/user/user.service';


@Component({
  selector: 'app-more-reset-password',
  templateUrl: './more-reset-password.component.html',
  styleUrls: ['./more-reset-password.component.scss']
})
export class MoreResetPasswordComponent implements OnInit, OnDestroy {

  // Form values & state
  public form: FormGroup;
  public formSubmitted = false;
  public isLoading = false;
  public error = null;
  public readonly INVALID_PASSWORD_ERROR = 'invalid_password';

  constructor(private driverService: DriverService,
    private formBuilder: FormBuilder,
    private router: Router,
    private popinService: PopinService,
    private featureService: FeatureService,
    private gtmService: GtmService,
    private snackBarService: SnackbarService,
    private userManagementService: UserManagementService,
    private userService: UserService) {

  }

  ngOnInit() {
    this.popinService.opened(true);
    this.form = this.formBuilder.group({
      newPassword: [, [Validators.required, CustomValidators.securePassword], [passwordUserNameValidator(this.userService)]],
      confirmPassword: [, Validators.required],
      oldPassword: ['', Validators.required],
    });
    this.form.get('confirmPassword').setValidators([CustomValidators.required, sameAsValidator(this.form.get('newPassword'))]);
  }

  onSubmit() {
    this.formSubmitted = true;
    this.error = null;

    if (this.form.valid) {

      this.isLoading = true;

      const payload = {
        currentPassword: this.form.get('oldPassword').value,
        newPassword: this.form.get('newPassword').value
      };
      this.userManagementService.changeCurrentUserPassword(payload.currentPassword, payload.newPassword).subscribe(
        () => {
          this.onUpdatePasswordSuccess();
        },
        (httpError) => {
          this.onUpdatePasswordFail(httpError.error);
        });
    }
  }

  public resetConfirmationField(): void {
    this.form.controls.confirmPassword.setValue('');
    this.form.controls.confirmPassword.updateValueAndValidity();
  }

  private onUpdatePasswordSuccess() {
    this.gtmService.sendEvent(GTM_EVENTS.changePassword);
    this.isLoading = false;
    this.onBackClick('personal-informations');
    this.snackBarService.success('global-reset_password_panel-snackbar_success');
  }

  private onUpdatePasswordFail(error) {
    this.isLoading = false;
    if (error === this.INVALID_PASSWORD_ERROR) {
      this.error = 'global-generic_terms-incorrect_input';
    } else {
      this.error = 'global-generic_terms-generic_server_error';
    }
  }

  ngOnDestroy() {
    // Back navigator
    this.popinService.opened(false);
  }

  onBackClick(path) {
    this.popinService.opened(false);
    this.router.navigate([this.router.url.replace('reset-password', path)]);
  }


}
