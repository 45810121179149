import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TransferHttpOptions, TextTransferHttpOptions } from './transfer-http-options';
import { HttpConst } from './http.const';
import { AppStorageService } from '../storage/common/app-storage.service';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
@Injectable()
export class HttpService {
    constructor(
        private httpClient: HttpClient,
        private storageService: AppStorageService,
        private authStorageService: AuthStorageService
    ) {
    }
    public get<T>(url: string, isAnonymousCall: Boolean = false, includeHeaders: Boolean = true): Observable<T> {
        return this.sendRequest(() => {
            return this.httpClient.get<T>(url, this.getOptions(isAnonymousCall, true, includeHeaders));
        });
    }

    public getString(url: string, isAnonymousCall: Boolean = false): Observable<any> {
        let options: TextTransferHttpOptions;
        let headers = new HttpHeaders();
        headers = headers.append(HttpConst.headers.ContentType, 'application/json');
        headers = headers.append(HttpConst.headers.CountryCode, this.storageService.countrySelected);
        headers = headers.append(HttpConst.headers.UserRole, this.storageService.currentUserRole);
        if (!isAnonymousCall) {
            headers = headers.append('Authorization', 'Bearer ' + this.authStorageService.accessToken);
        }
        options = { headers, responseType: 'text' };
        return this.sendRequest(() => {
            return this.httpClient.get(url, options);
        });
    }

    /* For IE */
    public getNoCache<T>(url: string): Observable<T> {
        const options = this.getOptions(false, false);
        options.headers = options.headers.append('Cache-Control', 'no-cache');
        options.headers = options.headers.append('Pragma', 'no-cache');
        options.headers = options.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        return this.sendRequest(() => {
            return this.httpClient.get<T>(url, options);
        });
    }

    public post<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
        return this.sendRequest(() => {
            return this.httpClient.post<T>(url, body, this.getOptions(isAnonymousCall));
        });
    }

    public postFile<T>(url: string, body: any): Observable<T> {
        return this.sendRequest(() => {
            return this.httpClient.post<T>(url, body, this.getOptions(false, false));
        });
    }

    public put<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
        return this.sendRequest(() => {
            return this.httpClient.put<T>(url, body, this.getOptions(isAnonymousCall));
        });
    }
    // public delete<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    //   return this.sendRequest(() => {
    //     return this.httpClient.delete<T>(url, this.getOptions(isAnonymousCall));
    //   });
    // }
    // public patch<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<T> {
    //   return this.sendRequest(() => {
    //     return this.httpClient.patch<T>(url, body, this.getOptions(isAnonymousCall));
    //   });
    // }
    // public head<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    //   return this.sendRequest(() => {
    //     return this.httpClient.head<T>(url, this.getOptions(isAnonymousCall));
    //   });
    // }
    // public options<T>(url: string, isAnonymousCall: Boolean = false): Observable<T> {
    //   return this.sendRequest<T>(() => {
    //     return this.httpClient.options<T>(url, this.getOptions(isAnonymousCall));
    //   });
    // }

    private sendRequest<T>(callback: () => Observable<T>): Observable<T> {
        return callback().pipe(
            catchError((httpError) => {
                return throwError(httpError);
            },
            ));
    }

    errorHandler(error: any): void {
        console.log(error);
    }

    private getOptions(isAnonymousCall: Boolean, useJsonContentType = true, includeHeaders: Boolean = true): TransferHttpOptions {
        let options: TransferHttpOptions;
        let headers = new HttpHeaders();
        if (useJsonContentType) {
            headers = headers.append(HttpConst.headers.ContentType, 'application/json');
        }
        if (isAnonymousCall) {
            options = {
                headers,
                withCredentials: false,
                responseType: 'json'
            };
        } else {
            if (includeHeaders) {
                headers = headers.append(HttpConst.headers.CountryCode, this.storageService.countrySelected);
                headers = headers.append(HttpConst.headers.UserRole, this.storageService.currentUserRole);
            }

            headers = headers.append('Authorization', 'Bearer ' + this.authStorageService.accessToken);
            options = { headers, responseType: 'json' };
        }
        return options;
    }
}
