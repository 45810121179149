import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { EmailValidator } from '../../shared/app-forms/validators/email.validator';
import { DateValidator } from '../../shared/app-forms/validators/date.validator';
import { DriverService } from '../../driver/shared/driver.service';
import { PopinService } from '../../shared/ui/popin.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { HelpService } from '../shared/help.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { ConfigurationModel } from '../../shared/config/configuration.model';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import * as moment from 'moment';
import { contractIsStatusOfType } from '../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../driver/shared/contract/contract-status.const';
import { MaskComponent } from '../../shared/mask/mask.component';
import { DeclareDamageRequest } from '../shared/models/declare-damage-request.model';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRole } from 'app/driver/shared/models/user-role.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { DocumentsConst } from 'app/documents/shared/documents.const';
import { DocumentsService } from 'app/documents/shared/documents.service';

@Component({
  selector: 'app-help-damage-declaration',
  templateUrl: './help-damage-declaration.component.html',
  styleUrls: ['./help-damage-declaration.component.scss'],
})
export class HelpDamageDeclarationComponent implements OnInit, OnDestroy {
  public newUserProfile: UserProfile;
  public newUserRole: UserRole;
  public form: FormGroup;
  public datasSelectMenu = [];
  public indexSelectMenu = -1;
  public addressSelected: string;
  public mobile: boolean;
  public selectedContractId: string;
  public selectedVehicle: string;
  public isLoading = false;
  public error = null;
  public countryPhoneCode: string;
  @ViewChild('selectAutocomplete')
  public selectAutocomplete;
  private isTimeSelected: boolean;
  public file = [];
  public fileFormats = DocumentsConst.fileFormats;
  urls = [];
  documentids = [];
  selectedFile = [];
  filesValidate: boolean;
  constructor(
    private driverService: DriverService,
    private helpService: HelpService,
    private router: Router,
    private formBuilder: FormBuilder,
    private popinService: PopinService,
    private appConfig: AppConfigService,
    private element: ElementRef,
    private translationService: TranslationService,
    private configurationService: ConfigurationService,
    private gtmService: GtmService,
    private snackBarService: SnackbarService,
    private appStorageService: AppStorageService
  ) {
    this.isTimeSelected = false;
    this.setFormData();
  }

  private setFormData(): void {
    this.driverService.newDriverStream.subscribe((newDriver: UserProfile) => {
      this.newUserProfile = newDriver;
      this.newUserRole = this.driverService.getNewUserRole(newDriver);
      // Parse driver's vehicles and push in datasSelectMenu
      this.datasSelectMenu = this.newUserRole.contracts
        .filter((contract: UserRoleContract) => {
          return contractIsStatusOfType(
            contract,
            ContractConsolidatedStatusType.Active
          );
        })
        .map((contract: UserRoleContract) => {
          return {
            id: contract.reference,
            label: `${contract.vehicle.brand} ${contract.vehicle.plate}`,
          };
        });

      if (this.datasSelectMenu.length === 1) {
        this.indexSelectMenu = 0;
      }
      this.generateFormGroup();
    });
  }

  ngOnInit() {
    this.popinService.opened(true);
    this.mobile = this.appConfig.isMobileTablet;
    this.configurationService
      .getConfItems([
        this.appStorageService.countrySelected,
        'CountryPhoneCode',
      ])
      .subscribe(
        (feedback: ConfigurationModel) => {
          this.countryPhoneCode = feedback.value;
        },
        (e) => {
          this.countryPhoneCode = 'error occured';
        }
      );
  }

  onSubmit() {
    if (this.selectedFile.length < 1) {
      this.filesValidate = true;
    }
    if (this.form.valid) {
      this.isLoading = true;
      const damageDeclarationParams: DeclareDamageRequest = {
        repairCity: this.form.get('address').value,
        carDamage: this.form.get('carDamage').value,
        date: this.getDateTime(
          this.form.get('date').value,
          this.form.get('time').value
        ),
        wasSomeoneInjured: !!this.form.get('injuredPeople').value,
        hasOtherDamage: !!this.form.get('otherDamage').value,
        otherDamageDescription: this.form.get('otherDamageDescription')
          ? this.form.get('otherDamageDescription').value
          : null,
        vehicle: this.selectedVehicle,
        wasVehicleMoving: !!this.form.get('vehicleBehavior').value,
        culture: this.translationService.language,
        contractReference: this.selectedContractId,
        documentIds: this.documentids,
      };

      this.uploadDocument(damageDeclarationParams);
    } else {
      this.backToTop();
    }
  }

  generateFormGroup() {
    this.form = this.formBuilder.group({
      vehicle: ['', Validators.required],
      date: ['', DateValidator.isPast],
      time: ['', Validators.required],
      address: ['', Validators.required],
      vehicleBehavior: ['', Validators.required],
      injuredPeople: ['', Validators.required],
      carDamage: ['', Validators.required],
      otherDamage: ['', Validators.required],
      documentIds: [],
    });

    this.formValuesChangesListener();
  }

  formValuesChangesListener() {
    this.form.get('otherDamage').valueChanges.subscribe((data) => {
      if (!!data) {
        this.form.addControl(
          'otherDamageDescription',
          new FormControl('', Validators.required)
        );
      } else {
        this.form.removeControl('otherDamageDescription');
      }
    });
  }

  updateSelectVehicle(e) {
    this.form.get('vehicle').setValue(e.label);
    this.selectedContractId = e.id;
    this.selectedVehicle = e.label;
  }

  updateSelectAddress(label) {
    this.form.get('address').setValue(label);
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  backToTop() {
    const el = this.element.nativeElement.querySelector('.sticky-content');
    const scrollInterval = setInterval(function () {
      el.scrollTop = el.scrollTop - 15;
      if (el.scrollTop <= 0) {
        clearInterval(scrollInterval);
      }
    }, 1);
  }

  onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  getDateTime(strDate: string, strTime: string) {
    const dateTime = moment(strDate + ' ' + strTime);
    return dateTime.parseZone().toJSON();
  }

  // The 3 following methods are used as a hack to fix an issue with the time picker
  onflatpickrOpen(event: any) {
    event.instance._input.parentElement.parentElement.classList.add(
      'has-value'
    );
  }

  onflatpickrClose(event: any) {
    if (!this.isTimeSelected) {
      event.instance._input.parentElement.parentElement.classList.remove(
        'has-value'
      );
      event.instance._input.value = '';
    }
  }

  onflatpickrChange(event: any) {
    this.isTimeSelected = true;
  }

  public fileChange(event): void {
    this.file = [];
    this.selectedFile = event.target.files;
    this.urls = [];
    this.file =
      this.selectedFile.length > 1
        ? this.selectedFile.length + 'file selected'
        : this.selectedFile[0].name;
    if (this.selectedFile && this.selectedFile[0]) {
      const numberOfFiles = this.selectedFile.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFile[i]);
      }
    }
  }

  async uploadDocument(damageDeclarationParams) {
    const titale = { Title: damageDeclarationParams.carDamage };
    const formData = new FormData();
    for (let i = 0; i < this.selectedFile.length; i++) {
      formData.append('fileData', JSON.stringify(titale));
      formData.append('file', this.selectedFile[i]);
      await this.helpService.uploadDocumentAction(formData).subscribe(
        (documentIds) => {
          if (documentIds) {
            this.documentids.push(documentIds);
          }
          if (i === this.selectedFile.length - 1) {
            this.damageDeclare(damageDeclarationParams);
          }
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  public damageDeclare(damageDeclarationParams) {
    this.helpService.declareDamage(damageDeclarationParams).subscribe(
      (response) => {
        this.isLoading = false;
        this.snackBarService.success('help-declare_damage_panel-form_success');
        this.onBackClick();
        this.gtmService.sendEvent(GTM_EVENTS.AssistanceDamageDeclaration);
      },
      (error) => {
        this.isLoading = false;
        if (error.status && error.status.toString().substring(0, 1) === '4') {
          this.error = 400;
        } else {
          this.error = true;
        }
        this.backToTop();
        this.removeDocument();
      }
    );
  }

  public removeDocument() {
    this.helpService.deleteDocument(this.documentids).subscribe((res) => {
      this.isLoading = false;
    });
  }
}
