import { environment } from 'environments/environment';

export const DriverConst = {
  api: {
    contractbase: environment.apigatewayUrl + '/apigateway/Contract/api/Customer/',
    userbase: environment.apigatewayUrl + '/apigateway/User/api/users/',
    userManagementBase: environment.apigatewayUrl + '/apigateway/usermanagement/api/',
    endpoints: {
      update: 'update',
      updateEnterpriseinfo: 'enterpriseinfo/update',
      updatePassword: 'updatepassword',
      driverInfo: 'GetUserProfile',
      updateUser: 'User/UserProfile',
      GetFleetManagers: 'User/FleetManagers',
    }
  }
};

export enum UserRoles {
  Driver = 'Driver',
  FleetManager = 'FleetManager',
  Prospect = 'Prospect',
  Commercial = 'Commercial',
  Administrator = 'Administrator',
  Supplier = 'Supplier',
}
