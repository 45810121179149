import { ContractService } from '../../../driver/shared/contract/contract.service';
import { Router } from '@angular/router';
import { Driver } from '../../../driver/shared/models/driver.model';
import { AfterViewChecked, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DriverService } from '../../../driver/shared/driver.service';
import { AppConfigService } from '../../config/app-config.service';
import { contractsContainsStatusOfType } from '../../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../../driver/shared/contract/contract-status.const';
import { combineLatest } from 'rxjs';
import { ConfigurationService } from '../../config/configuration.service';
import { AppStorageService } from '../../storage/common/app-storage.service';
import { FeatureService } from '../../../shared/feature/feature.service';
import { FeatureConst } from '../../../shared/feature/feature.consts';
import { COUNTRIES } from '../../../shared/translation/translation.settings';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: ['user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy, AfterViewChecked {

  public anchorMovedOnInit = false;
  public isDesktop = false;

  public hasVehicules = true;
  public hasVan = false;
  public hasBike = false;
  public hasCar = false;
  public isProspect: boolean;
  public isQuoterActivated = false;
  public isSmartCareActivated: boolean;
  public isAldQuoteActivated = true;
  public isCarSelectorActivated: boolean;

  public driver: Driver = null;
  public hasDriverActiveContract = false;
  public numNewNotifications = 0;
  @ViewChild('decorativeAnchor')
  public decorativeAnchor: ElementRef;
  private subscriptions = [];
  public countryLogo: string;

  constructor(private appConfig: AppConfigService,
    private element: ElementRef,
    public driverService: DriverService,
    private contractService: ContractService,
    private router: Router,
    private configurationService: ConfigurationService,
    private featureService: FeatureService,
    private appStorageService: AppStorageService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);

    if (this.isDesktop && this.element.nativeElement.querySelector('.active a')) {
      this.moveAnchor(this.element.nativeElement.querySelector('.active a'));
    }
  }

  ngOnInit() {
    const countrySelected = this.appStorageService.countrySelected;
    const countryName = _.findKey(COUNTRIES, { 'country_code': countrySelected });
    this.countryLogo = COUNTRIES[countryName].company_logo;

    this.getVehiculeType();
    this.onResize();
    combineLatest(
      this.featureService.isFeatureActivated(FeatureConst.features.Quoter),
      this.featureService.isFeatureActivated(FeatureConst.features.CarSelector),
      this.featureService.isFeatureActivated(FeatureConst.features.AldQuote),
      this.configurationService.getConfItems([this.appStorageService.countrySelected, 'CarSelectorUrl']),
      this.configurationService.getConfItems([this.appStorageService.countrySelected, 'AldQuoteUrl']),
      this.configurationService.getConfItems([this.appStorageService.countrySelected, 'SmartCareUrl'])
    ).subscribe(([isQuoterActivated, isCarSelectorActivated, isAldQuoteActivated, carSelectorUrl, aldQuoteUrl, isSmartCareActivated]) => {
      this.isQuoterActivated = isQuoterActivated;
      this.isAldQuoteActivated = isAldQuoteActivated && aldQuoteUrl ? true : false;
      this.isCarSelectorActivated = isCarSelectorActivated && carSelectorUrl ? true : false;
      this.isSmartCareActivated = isSmartCareActivated ? true : false;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewChecked() {
    if (!this.anchorMovedOnInit && this.element.nativeElement.querySelector('.active a')) {
      this.moveAnchor(this.element.nativeElement.querySelector('.active a'));
      this.anchorMovedOnInit = true;
    }
  }

  // EventHandler
  public onClick($event) {
    this.moveAnchor($event.target);
  }

  goTo(path) {
    if (this.contractService.currentContractId && path[0] === '/cars') {
      path[1] = this.contractService.currentContractId;
    }
    this.router.navigate(path);
  }

  private getVehiculeType() {
    const driverSubscription = this.driverService.driverStream.subscribe(
      (driver: Driver) => {
        if (driver) {
          this.hasVehicules = false;
          this.hasDriverActiveContract = contractsContainsStatusOfType(driver.contracts, ContractConsolidatedStatusType.Active);
          this.driver = driver;
          if (driver.carMakes.length > 1) {
            this.hasVehicules = true;
          } else if (driver.carMakes.length > 0) {
            if (driver.carMakes[0].type === 'van') {
              this.hasVan = true;
            } else if (driver.carMakes[0].type === 'bike') {
              this.hasBike = true;
            } else {
              this.hasCar = true;
            }
          }
          if (driver.roles && driver.roles.length) {
            this.isProspect = driver.roles.includes('Prospect' || 'prospect' || 'PROSPECT');
          }
        }
      }, () => {
      });

    this.subscriptions.push(driverSubscription);
  }

  private findAncestorItem(el) {
    while ((el = el.parentNode) && !el.classList.contains('item')) { }
    return el;
  }

  private moveAnchor(target) {
    const itemTarget = this.findAncestorItem(target);
    this.decorativeAnchor.nativeElement.style.top = itemTarget.offsetTop + 'px';
  }
}
