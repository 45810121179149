import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PopinService } from 'app/shared/ui/popin.service';
import { HelpService } from 'app/help/shared/help.service';
import { DriverService } from 'app/driver/shared/driver.service';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { contractIsStatusOfType } from 'app/driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from 'app/driver/shared/contract/contract-status.const';
import { SelectAutocompleteAddressComponent } from 'app/shared/app-forms/select-autocomplete-address/select-autocomplete-address.component';
import { ContractOrderDamageRepair } from 'app/help/shared/models/help-driver-assistance.model';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';

@Component({
  selector: 'app-order-damage-repair',
  templateUrl: './order-damage-repair.component.html',
  styleUrls: ['./order-damage-repair.component.scss']
})
export class HelpOrderDamageRepairComponent implements OnInit, OnDestroy {

  @ViewChild('selectAutocompleteAddress')
  public selectAutocomplete: SelectAutocompleteAddressComponent;

  public error = null;
  public addressSelected: string;
  public form: FormGroup;
  public newUserProfile: UserProfile;
  public vehicleList = [];
  public vehicleSelected = 0;
  public submitStatus = 'initial';

  constructor(
    private router: Router,
    private popinService: PopinService,
    private helpService: HelpService,
    private gtmService: GtmService,
    private driverService: DriverService) {
  }

  ngOnInit() {
    this.getDriver();
    this.popinService.opened(true);
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.buildForm();
      });
  }

  public updateVehicleList(obj): void {
    this.form.get('contractId').setValue(obj.id);
  }

  public updateSelectAddress(label) {
    this.form.get('carPickUpAddress').setValue(label);
  }

  private generateVehicleList(): void {
    this.vehicleList = this.driverService.getNewUserRole(this.newUserProfile).contracts.filter((contract: UserRoleContract) => {
      return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
    }).map((contract: UserRoleContract) => {
      return {
        id: contract.reference,
        label: `${contract.vehicle.brand} ${contract.vehicle.plate}`
      };
    });

    if (this.vehicleList.length === 1) {
      this.vehicleSelected = 0;
    }
  }

  private buildForm(): void {
    this.form = new FormGroup({
      contractId: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      carPickUpAddress: new FormControl('', [Validators.required]),
      needReplacementCar: new FormControl(false, [Validators.required]),
      extraInformation: new FormControl(''),
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.submitStatus = 'pending';
      const orderDamageRepairRequest = Object.assign({}, this.form.value) as ContractOrderDamageRepair;
      this.helpService.orderDamageRepair(orderDamageRepairRequest).subscribe(() => {
        this.submitStatus = 'success';
        this.gtmService.sendEvent(GTM_EVENTS.orderDamageRepair);
      }, () => {
        this.submitStatus = 'initial';
        this.error = 500;
      })
    }
  }

  public onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  ngOnDestroy() {
  }
}
