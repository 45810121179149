export const GTM_EVENTS = {
  loginSuccess: {
    event: 'connection-loginSucceed',
    eventCategory: 'Connection',
    eventAction: 'Login succeed',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'driver',
  },
  logout: {
    event: 'connection-logout',
    eventCategory: 'Connection',
    eventAction: 'Logout',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'driver',
  },
  editOwnProfile: {
    event: 'connection-editOwnProfile',
    eventCategory: 'User account management',
    eventAction: 'Edit own profile',
    eventLabel: '<user-role>',
    eventDetails: '<country>',
    plateforme: 'manager',
  },
  changePassword: {
    event: 'userAccountManagement-passwordChange',
    eventCategory: 'User account management',
    eventAction: 'Password change',
    plateforme: 'driver',
  },
  updateMileage: {
    event: 'mileage-updateMyMileage',
    eventCategory: 'Mileage',
    eventAction: 'Update my mileage',
    plateforme: 'driver',
  },
  makeAQuotationIntention: {
    event: 'quotation-makeAQuotationIntention',
    eventCategory: 'Quotation',
    eventAction: 'Make a quotation intention',
    eventLabel: '<click-url>',
    plateforme: 'driver',
  },

  clickNavigation: {
    event: 'navigation-outboundLink',
    eventCategory: 'Navigation',
    eventAction: 'Outbound link',
    eventLabel: '<click-text>',
    plateforme: 'driver',
  },

  uploadDocument: {
    event: 'document-uploadADriverDocument',
    eventCategory: 'Document',
    eventAction: 'Upload a driver document',
    plateforme: 'driver',
  },
  downloadDocument: {
    event: 'document-downloadDocument',
    eventCategory: 'Download',
    eventAction: 'Download a document',
    eventLabel: '<document-name>',
    eventDetails: '<document-type>',
    plateforme: 'driver',
  },
  assistanceCall: {
    event: 'assistance-call',
    eventCategory: 'Assistance',
    eventAction: 'Call',
    plateforme: 'driver',
  },
  assistanceCreateACase: {
    event: 'assistance-createACase',
    eventCategory: 'Assistance',
    eventAction: 'Create a case',
    plateforme: 'driver',
  },
  AssistanceDamageDeclaration: {
    event: 'assistance-damageDeclaration',
    eventCategory: 'Assistance',
    eventAction: 'Help - damage declaration',
    plateforme: 'driver',
  },
  askPermissionToTakeAbroad: {
    event: 'assistance-askPermissionToTakeAbroad',
    eventCategory: 'Assistance',
    eventAction: 'Ask permision to take abroad',
    plateforme: 'driver',
  },
  orderDamageRepair: {
    event: 'assistance-orderDamageRepair',
    eventCategory: 'Assistance',
    eventAction: 'Help - order damage repair',
    plateforme: 'driver',
  },
  orderMaintenance: {
    event: 'assistance-orderMaintenance',
    eventCategory: 'Assistance',
    eventAction: 'Help - order Maintenance',
    plateforme: 'driver',
  },
  forgetPassword: {
    event: 'userAccountManagement-forgetPassword',
    eventCategory: 'User account management',
    eventAction: 'Password change',
    plateforme: 'driver',
  },
  signUpUser: {
    event: 'userAccountManagement-signUp',
    eventCategory: 'User account management',
    eventAction: 'Create user',
    plateforme: 'driver',
  },
  trafficFines: {
    event: 'services-trafficFines',
    eventCategory: 'Traffic Fine',
    eventAction: 'Service-Traffic fine',
    plateforme: 'driver',
  },
  smartCare: {
    event: 'services-smartCare',
    eventCategory: 'smart care',
    eventAction: 'Service- smart care',
    plateforme: 'driver',
  }
};
