﻿import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../pipes/pipes.module';
import { CustomRouterLinkActiveModule } from 'app/shared/directives/custom-router-link-active/custom-router-link-active.module';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { MorePersonalInformationComponent } from './more-personal-information/more-personal-information.component';
import { StickyHeaderModule } from '../sticky-header/sticky-header.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AppFormsModule } from '../app-forms/app-forms.module';
import { MoreResetPasswordComponent } from './more-reset-password/more-reset-password.component';
import { SvgInlineModule } from '../svg-inline/svg-inline.module';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { ClickOutsideDirective } from '../directives/click-outside/click-outside.directive';
import { MyPreferencesComponent } from './my-preferences/my-preferences.component';
import { MyAldUiModule } from '../ui/myald-ui/myald-ui.module';
import { MaterialModule } from '../ui/material/material.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    RouterModule,
    PipesModule,
    TranslateModule,
    CustomRouterLinkActiveModule,
    StickyHeaderModule,
    ReactiveFormsModule,
    AppFormsModule,
    SvgInlineModule,
    MyAldUiModule,
    MaterialModule,
    MatSlideToggleModule
  ],
  declarations: [
    UserMenuComponent,
    HeaderMenuComponent,
    MorePersonalInformationComponent,
    MoreResetPasswordComponent,
    ClickOutsideDirective,
    MobileMenuComponent,
    MyPreferencesComponent,
  ],
  exports: [
    UserMenuComponent,
    HeaderMenuComponent,
    MobileMenuComponent
  ]
})
export class AppMenuModule {
}
