import { Injectable } from '@angular/core';
import { AppStorageService } from '../storage/common/app-storage.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { DidomiCookieConsentViewModel } from './didomi-cookies.model';
import { TranslationStorageConst } from '../storage/translation/translation-storage.const';
const LOCAL_HOST = 'localhost';

@Injectable()
export class CookiesService {

  constructor(private appStorageService: AppStorageService) { }

  public getCookiesPolicyDocumentUrl(languageCode): string {
    return `${environment.publicDocumentsBaseUrl}/${this.appStorageService.countrySelected}/cookies-charter-${languageCode}.pdf`;
  }

  public loadConfigFor(configDetails: DidomiCookieConsentViewModel): Observable<any> {
    return new Observable((observer) => {
      const culture = this.appStorageService.get(TranslationStorageConst.keys.lang);
      const countryCode = this.appStorageService.countrySelected ? this.appStorageService.countrySelected : 'IN';
      configDetails.gtmKey = `${environment.gtmKey}`;
      configDetails.app.apiKey = `${environment.didomiApiKey}`;
      configDetails.app.privacyPolicyURL = `${environment.publicDocumentsBaseUrl}/${countryCode}/cookies-charter-${culture}.pdf`;
      observer.next(configDetails);
      observer.complete();
    });
  }

  public getDomain(hostname: string): string {
    let domain: string;
    if (!environment.production) {
      if (hostname === LOCAL_HOST) {
        domain = LOCAL_HOST;
      } else {
        domain = `.${hostname.split('.')[hostname.split('.').length - 2]}` + `.${hostname.split('.')[hostname.split('.').length - 1]}`;
      }
    } else {
      domain = `.${hostname.replace(/^[^.]+\./g, '')}`;
    }
    return domain;
  }

  public deleteCookies(name: string, domain: string): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + (-1 * 24 * 60 * 60 * 1000));
    const cName = `${name}= `;
    const expires = `expires=${d.toUTCString()}`;
    const cPath = `path=/`;
    const cDomain = `domain=${domain}`;
    document.cookie = `${cName};${cDomain};${expires};${cPath}`;
  }

  public getAllCookie(): string[] {
    const cookieArray = [];
    document.cookie.split('; ').forEach((cookie) => {
      cookieArray.push(cookie.split('=')[0]);
    });
    return cookieArray;
  }
}
