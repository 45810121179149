import { RequestParameter } from '../../shared/common/request-parameter.model';
import { Driver } from './models/driver.model';
import { Session } from '../../shared/common/api.settings';
import { Injectable } from '@angular/core';
import { OnErrorService } from '../../shared/ui/on-error.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable, throwError } from 'rxjs';
import { UserService } from '../../shared/user/user.service';
import { DriverConst } from './driver.const';
import { ApiService } from '../../shared/common/api.service';
import { FeatureService } from 'app/shared/feature/feature.service';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { TranslationStorageService } from 'app/shared/storage/translation/translation-storage.service';
import { FleetManagerLight } from 'app/documents/shared/models/fleet-manager-light.model';
import { UserProfile } from './models/user-profile.model';
import { UserRole } from './models/user-role.model';
import { UserRoles } from '../../shared/user/userRoles.const';
import { Router } from '@angular/router';

@Injectable()
export class DriverService {

  public driverStream;
  public firstContractId: string;
  private _driver: Driver;
  public newDriverStream;
  private _newDriver: UserProfile;

  constructor(
    private router: Router,
    private api: ApiService,
    private userService: UserService,
    private onErrorService: OnErrorService,
    public featureService: FeatureService,
    private userManagementService: UserManagementService,
    private translationStorage: TranslationStorageService) {
    this.driverStream = new BehaviorSubject<Driver>(this._driver);
    this.newDriverStream = new BehaviorSubject<UserProfile>(this._newDriver);
  }

  get driver(): Observable<Driver> {
     if (!this._driver) {
      return this.getDriver();
    };

    return Observable.of(this._driver);
  }

  get newDriver(): Observable<UserProfile> {
    if (!this._newDriver) {
      return this.getNewDriver();
    };
    const cultureFromStorage = this.translationStorage.lang.toString();
    this._driver.culture = cultureFromStorage ||  'en-US';
    return Observable.of(this._newDriver);
  }

  public getNewUserRole(newUserProfile: UserProfile): UserRole {
    const newUserRole = newUserProfile.userRoles.find((userRole: UserRole) => {
      return (userRole.role.toLocaleLowerCase() === UserRoles.Driver || userRole.role.toLocaleLowerCase() === UserRoles.Prospect)
    });
    return newUserRole;
  }

  public updateEnterpriseInfos(contactLastName: string, contactFirstName: string, contactEmail: string, contactPhone: string) {
    const params: RequestParameter[] = [];
    params.push(new RequestParameter('driverId', Session.DRIVER_ID));
    params.push(new RequestParameter('contactLastName', contactLastName));
    params.push(new RequestParameter('contactFirstName', contactFirstName));
    params.push(new RequestParameter('contactEmail', contactEmail));
    params.push(new RequestParameter('contactPhone', contactPhone));

    return this.api.post(DriverConst.api.endpoints.updateEnterpriseinfo, params)
      .map(response => {
        return response.json();
      });
  }

  public updateProfile(payload) {
    payload['userGuid'] = this.userService.userId;
    return this.api.post(DriverConst.api.userManagementBase + DriverConst.api.endpoints.updateUser, payload)
      .map(response => {
      });
  };

  public getLicensePlates(): string[] {
    const licensePlates = [];

    if (this._driver.carMakes) {
      this._driver.carMakes.forEach(carMake => {
        licensePlates.push(carMake.licensePlate);
      });
    }

    return licensePlates;
  }

  private getDriver(retry = 1): Observable<Driver> {
    return this.userManagementService.getUserProfile().map((response: Driver) => {
      this._driver = response;
      const cultureFromStorage = this.translationStorage.lang.toString();
      this._driver.culture = cultureFromStorage ||  'en-US';

      if (this._driver.contracts && this._driver.contracts.length !== 0) {
        this.firstContractId = this._driver.contracts[0].id;
      }
      if (this._driver) {
        this.driverStream.next(this._driver);
      }

      return this._driver;
    }).catch(error => {
      if (retry) {
        return this.getDriver(--retry);
      } else {
        if (error.status !== 419) {
          this.onErrorService.updateMaintenanceStatus(true);
        }
        throwError(error);
      }
    });
  }

  private getNewDriver(retry = 1): Observable<UserProfile> {
    return this.userManagementService.getNewUserProfile().map((response: UserProfile) => {
      this._newDriver = response;
      if (this._newDriver) {
        this.newDriverStream.next(this._newDriver);
      }
      return this._newDriver;
    }).catch(error => {
      if (retry) {
        return this.getNewDriver(--retry);
      } else {
        if (error.status !== 419) {
          this.onErrorService.updateMaintenanceStatus(true);
        }
        this.router.navigate(['/user-error']);
        throwError(error);
      }
    });
  }

  public clearDriver(): void {
    this._driver = null;
    this._newDriver = null;
    this.driverStream.next(null);
    this.newDriverStream.next(null);
  }

  public getFleetManagers(): Observable<FleetManagerLight[]> {
    return this.api.get(DriverConst.api.userManagementBase + DriverConst.api.endpoints.GetFleetManagers);
  }
}
