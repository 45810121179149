﻿import { AuthStorageService } from '../../shared/storage/auth/auth-storage.service';
import { ApplicationRef, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/map';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { AuthService } from '../../shared/authentication/auth.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../../shared/helper/logger.service';
import { Subscription } from 'rxjs';
import { WindowRef } from '../../shared/helper/window.ref';
import { filter, finalize, takeWhile, tap } from 'rxjs/operators';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';
import { UserService } from '../../shared/user/user.service';
import { DocumentRef } from 'app/shared/helper/document.ref';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';
import { UserManagementService } from 'app/shared/user/userManagement.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class LoginComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  @ViewChild(FormGroupDirective)
  public formDirective: FormGroupDirective;
  public formSubmitted = false;
  public isLoading: boolean;
  @HostBinding('@routeAnim')
  public routeAnimState: string;

  public adfsIframe;
  public loginErrorMessage: string;
  public expiredPasswordError = false;
  public useNumericKeypad = false;
  public readonly loginErrors = {
    invalidCreds: 'Wrong login or password',
    getJwtFailed: 'Unable to get jwt token',
    getUserInfoFailed: 'Could not retrieve user\'s infos'
  };
  private navDirectionChangeSubscription: Subscription;
  private returnUrl = '';
  private isComponentActive = true;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public appConfig: AppConfigService,
    private authService: AuthService,
    private userService: UserService,
    private navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef,
    private storage: AuthStorageService,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private documentRef: DocumentRef,
    private userManagementService: UserManagementService,
    private gtmService: GtmService) {

    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

  ngOnInit() {
    const userName = this.storage.userName ? this.storage.userName : '';
    this.form = this.formBuilder.group({
      userid: [userName, [Validators.required, CustomValidators.customEmail]],
      password: ['', Validators.required]
    });

    this.form.controls.userid.valueChanges.pipe(
      takeWhile(() => this.isComponentActive),
      tap(email => this.userManagementService.loginEmail = email),
    ).subscribe();

    this.setupAdfsIframe();

    this.activatedRoute.queryParams
      .pipe(
        filter(params => params.returnUrl)
      )
      .subscribe(params => {
        this.returnUrl = params.returnUrl;
      });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
  }

  private setupAdfsIframe(): void {
    this.windowRef.nativeWindow.addEventListener(
      'message',
      this.adfsEventCallback,
      false);

    this.adfsIframe = this.documentRef.nativeDocument.getElementById('adfs_ifm') as HTMLIFrameElement;
    this.adfsIframe.src = environment.adfsUrl;
  }

  private adfsEventCallback = (e: any) => {
    const { data: eventData } = e;
    if (eventData.waitingForLogin && this.formSubmitted) {
      this.loginFail(this.loginErrors.invalidCreds);
    }
    if (eventData.isLogged) {
      this.windowRef.nativeWindow.removeEventListener('message', this.adfsEventCallback);
      this.loginSuccess(eventData.isLogged);
    }
  }

  public onControlFocus() {
    this.loginErrorMessage = null;
  }

  public onSubmit() {
    this.expiredPasswordError = false;
    this.loginErrorMessage = '';
    this.formSubmitted = true;

    if (this.form.valid) {
      const login = this.form.get('userid').value.trim();
      const password = this.form.get('password').value;

      this.userService.isPasswordExpired(login).pipe(
        takeWhile(() => this.isComponentActive),
        tap(isPasswordExpired => this.expiredPasswordError = isPasswordExpired),
        finalize(() => {
          if (!this.expiredPasswordError) {
            this.loginErrorMessage = '';
            this.isLoading = true;
            this.getBrowser() === 'Safari' ? this.loginFromUserService(login, password) :
              this.adfsIframe.contentWindow.postMessage({ login, password, rememberMe: true }, '*');
          }
        })
      ).subscribe();
    }
  }

  private loginFromUserService(login, password) {
    this.userManagementService.loginFromUserService(login, password).subscribe(
      (response) => {
        this.loginSuccessUserService(response);
      },
      (err) => {
        this.loginFail(this.loginErrors.invalidCreds);
      });
  }

  public loginSuccess(tokens) {
    const tokensObj = JSON.parse(tokens);
    this.navigationDirection.direction = 'forward';

    if (!this.authService.redirectIfWrongCountry(tokensObj)) {
      this.authService.setAuthStorage(tokensObj);
      this.onLoginSuccessNavigation();
    }
  }

  public loginSuccessUserService(tokens) {
    this.navigationDirection.direction = 'forward';
    if (!this.authService.redirectIfWrongCountry(tokens)) {
      this.authService.setAuthStorage(tokens);
      this.onLoginSuccessNavigation();
    }
  }

  private onLoginSuccessNavigation() {
    if (this.returnUrl !== '') {
      const currentLocation = this.windowRef.nativeWindow.location;
      this.windowRef.nativeWindow.location = currentLocation.protocol + '//' + currentLocation.host + this.returnUrl;
    } else {
      const redirectPath = this.appConfig.isMobile ? '/start/allow-notification' : '/feed';
      this.router.navigate([redirectPath]).then(() => {
        if (this.userService.getTokenRole() && this.userService.getCountry()) {
          this.gtmService.sendEvent(GTM_EVENTS.loginSuccess, this.userService.getTokenRole(), this.userService.getCountry().toUpperCase());
        }
      })
    }
  }

  private loginFail(explicitError: string, loginErrorMessage = 'start-login_page-login_error') {
    this.loginErrorMessage = loginErrorMessage;
    this.isLoading = false;

    if (explicitError === this.loginErrors.invalidCreds) {
    } else {
      this.loggerService.critical(explicitError);
    }
  }

  private getBrowser() {
    if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    }

    if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    }

    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';

    }
  }
}
