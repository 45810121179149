import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UserService } from 'app/shared/user/user.service';
import { PasswordRulesRegex } from '../validators/password.validator';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss']
})
export class PasswordValidationComponent {

  @Input() passwordControl: FormControl;
  public readonly PASSWORD_RULES_REGEX = Object.keys(PasswordRulesRegex);

  constructor(private userService: UserService) { }

  get isCheckingPasswordUsername(): boolean {
    return this.userService.isCheckingPasswordUsername.getValue();
  }

  public isPasswordUsernamePending(): boolean {
    return this.passwordControl.errors &&
    Object.keys(this.passwordControl.errors).some(key => this.PASSWORD_RULES_REGEX.includes(key));
  }

  public isPasswordUsernameValid(): boolean {
    return !this.passwordControl.getError('notContainUserName') &&
    this.passwordControl.value &&
    !this.isPasswordUsernamePending() &&
    !this.isCheckingPasswordUsername;
  }

  public isPasswordUsernameInvalid(): boolean {
    return this.passwordControl.getError('notContainUserName') &&
    this.passwordControl.value &&
    !this.isPasswordUsernamePending() &&
    !this.isCheckingPasswordUsername;
  }

}

