import { AuthService } from '../../authentication/auth.service';
import { TranslationService } from '../../translation/translation.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PhoneValidator } from '../../app-forms/validators/phone.validator';
import { DriverService } from '../../../driver/shared/driver.service';
import { Driver } from '../../../driver/shared/models/driver.model';
import { PopinService } from '../../ui/popin.service';
import { StickyHeaderFormService } from '../../ui/sticky-header-form.service';
import { ModalService } from '../../ui/modal.service';
import ModalCode from '../../modal-box/ModalCode';
import { ConfigurationService } from '../../config/configuration.service';
import { AppStorageService } from '../../storage/common/app-storage.service';
import { Subscription } from 'rxjs';
import { FeatureService } from '../../feature/feature.service';
import { FeatureConst } from '../../feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { combineLatest } from 'rxjs';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { GtmService } from 'app/shared/gtm/gtm.service';

@Component({
  selector: 'app-more-personal-information',
  templateUrl: './more-personal-information.component.html',
  styleUrls: ['./more-personal-information.component.scss']
})
export class MorePersonalInformationComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public submitStatus = 'initial';
  public openSubmitBox: boolean;
  public submitted = false;
  public addressSelected: string = null;
  public forgotPasswordRequest: boolean = null;
  public driver: Driver;
  public error = null;
  public availableLanguages = [];
  public modalSubscription: Subscription;
  @ViewChild('selectAutocomplete')
  public selectAutocomplete;
  @ViewChild('nativeForm')
  public nativeForm;
  private country: string;
  private popInContent = '';
  private subscriptionSubmitted: Subscription;
  private subscriptionCanceled: Subscription;
  private canEditProfile: boolean;
  private canEditOwnName: boolean;
  public userRoles;
  public pushNotification: boolean;

  constructor(private driverService: DriverService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private authService: AuthService,
    private popinService: PopinService,
    private stickyHeaderFormService: StickyHeaderFormService,
    private configurationService: ConfigurationService,
    private modalService: ModalService,
    private appStorageService: AppStorageService,
    private featureService: FeatureService,
    private gtmService: GtmService,
    private userManagementService: UserManagementService) {


    combineLatest(
      this.featureService.isFeatureActivated(FeatureConst.features.EditOwnNameActivated),
      this.featureService.isFeatureActivated(FeatureConst.features.EditMyProfileActivated)
    ).subscribe(([canEditOwnName, canEditProfile]) => {
      this.canEditProfile = canEditProfile;
      this.canEditOwnName = canEditOwnName;
      this.driverService.driverStream.subscribe((driver: Driver) => {
        if (driver) {
          this.driver = driver;
          this.generateFormGroup();
        }
      });
      this.userManagementService.getNewUserProfile().subscribe((UserProfile) => {
        if (UserProfile) {
          this.userRoles = UserProfile.userRoles;
          this.pushNotification = this.userRoles[0].notificationByPush;
          this.generateFormGroup();
        }
      });
    });



    this.translationService.translate('global-personal_information_panel-password_reset_request_sent_message').subscribe(
      content => {
        this.popInContent = content;
      }
    );

    this.subscriptionCanceled = this.stickyHeaderFormService.hasCanceled.subscribe(status => {
      if (status) {
        this.cancelFormValuesChange();
      }
    });

    this.subscriptionSubmitted = this.stickyHeaderFormService.hasSubmitted.subscribe(status => {
      if (status) {
        this.onSubmit();
      }
    });
    this.listenToModal();
  }

  ngOnInit() {
    this.popinService.opened(true);

    this.configurationService.getAvailableLanguages().subscribe(languages =>
      this.availableLanguages = languages
    );
  }

  generateFormGroup() {
    this.form = this.formBuilder.group({
      name: !this.canEditProfile || !this.canEditOwnName ? { value: this.driver.name, disabled: true } : [this.driver.name, Validators.required],
      email: [{ value: this.driver.mail, disabled: true }],
      address: this.canEditProfile ? [this.driver.address] : [{ value: this.driver.address, disabled: true }],
      postalCode: this.canEditProfile ? [this.driver.postalCode] : [{ value: this.driver.postalCode, disabled: true }],
      city: this.canEditProfile ? [this.driver.city] : [{ value: this.driver.city, disabled: true }],
      tel: this.canEditProfile ? [this.driver.phone] : [{ value: this.driver.phone, disabled: true }],
      login: [this.driver.login || this.driver.mail, Validators.required],
      password: ['**********'],
      language: [this.translationService.language, Validators.required],
      pushNotification: this.canEditProfile ? [this.pushNotification] : [{ value: this.pushNotification, disabled: true }]
    });
    this.addressSelected = this.driver.address;
    this.formValuesChangesListener();
  }

  formValuesChangesListener() {
    this.form.valueChanges.subscribe(data => {
      this.openSubmitBox = true;
    });
  }

  cancelFormValuesChange() {
    this.generateFormGroup();
    // Reset autocomplete
    if (this.selectAutocomplete) {
      this.selectAutocomplete.inputValue = this.driver.address;
      this.selectAutocomplete.addressLabel = this.driver.address;
      this.form.get('fullAddress').setValue(this.driver.address);
    }

    this.openSubmitBox = false;
    this.error = false;
  }

  updateSelectAddress(e) {
    this.form.get('fullAddress').setValue(e);
    this.openSubmitBox = true;
  }

  onSubmit() {
    this.error = null;
    this.submitted = true;
    // Because this form has not submit button directly into him,
    // we need to trigger submission manually to allow app-form-errors to display errors
    if (this.nativeForm) {
      this.nativeForm.onSubmit(null);
    }

    if (this.form.valid) {

      const payload = {
        address: this.form.get('address').value,
        postalCode: this.form.get('postalCode').value,
        city: this.form.get('city').value,
        phone: this.form.get('tel').value,
        name: this.form.get('name').value,
        country: this.appStorageService.countrySelected,
        notificationByPush: this.form.get('pushNotification').value,
      };

      this.submitStatus = 'pending';

      this.driverService.updateProfile(payload).subscribe(
        response => {
          this.submitStatus = 'initial';

          this.driver.city = payload.city;
          this.driver.address = payload.address;
          this.driver.postalCode = payload.postalCode;
          this.driver.phone = payload.phone;
          this.driver.name = payload.name;
          this.driver.country = payload.country;
          this.driver.notificationByPush = payload.notificationByPush;

          this.onBackClick();
          this.openSubmitBox = false;
          this.gtmService.sendEvent(GTM_EVENTS.editOwnProfile, '1');
        },
        error => {
          if (error.status && error.status.toString().substring(0, 1) === '4') {
            this.error = 400;
          } else {
            this.error = true;
          }
        }
      );

      this.openSubmitBox = false;
    } else {
      this.error = 400;
    }
  }

  forgotPasswordClickHandler(event) {
    event.preventDefault();
    this.modalService.modalCode = ModalCode.RESETPWD;
    this.modalService.openModal('global-reset_password_popin-text', 'global-reset_password_popin-title');
  }

  resetPassword(path) {
    this.popinService.opened(false);
    this.router.navigate([this.router.url.replace('personal-informations', path)]);
  }

  ngOnDestroy() {
    this.stickyHeaderFormService.hasSubmitted.next(false);
    this.stickyHeaderFormService.hasCanceled.next(false);

    this.subscriptionCanceled.unsubscribe();
    this.subscriptionSubmitted.unsubscribe();
    this.modalSubscription.unsubscribe();

    this.submitted = false;
    // Back navigator
    this.popinService.opened(false);
    this.forgotPasswordRequest = null;

  }

  onBackClick() {
    this.popinService.opened(false);
  }

  listenToModal() {
    this.modalSubscription = this.modalService.modalActionObservable.subscribe(
      status => {
        if (status === true && this.modalService.modalCode === ModalCode.RESETPWD) {
          this.forgotPassword();
          this.modalService.reset();
        }
      }
    );
  }

  forgotPassword() {
    event.preventDefault();

    this.userManagementService.authenticatedForgotPassword(this.driver.mail).subscribe(result => {
      this.forgotPasswordRequest = true;
    },
      (error) => {
        this.forgotPasswordRequest = false;
      });
  }
}
