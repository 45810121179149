import { SelectMenuComponent } from './select-menu/select-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringInputComponent } from './string-input/string-input.component';
import { InputErrorsComponent } from './input-errors/input-errors.component';
import { NumericKeypadComponent } from './numeric-keypad/numeric-keypad.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { AsyncButtonComponent } from './async-button/async-button.component';
import { LicenseInputComponent } from './license-input/license-input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorsComponent } from './form-errors/form-errors.component';
import { LoadingModule } from '../loading/loading.module';
import { StickyHeaderFormComponent } from './sticky-header-form/sticky-header-form.component';
import { SelectAutocompleteAddressComponent } from './select-autocomplete-address/select-autocomplete-address.component';
import { MultiselectComponent } from './multiselect/multiselect.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatChipsModule, MatProgressSpinnerModule } from '@angular/material';
import { PasswordValidatorDirective } from './validators/password.validator';
import { CustomEmailValidatorDirective } from './validators/custom-email.validator';
import { PasswordValidationComponent } from './password-validation/password-validation.component';

@NgModule({
  imports: [
    CommonModule,
    SvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    TranslateModule,
    MatSelectModule,
    MatChipsModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    StringInputComponent,
    InputErrorsComponent,
    NumericKeypadComponent,
    AsyncButtonComponent,
    LicenseInputComponent,
    PasswordInputComponent,
    FormErrorsComponent,
    StickyHeaderFormComponent,
    SelectMenuComponent,
    SelectAutocompleteAddressComponent,
    MultiselectComponent,
    PasswordValidatorDirective,
    CustomEmailValidatorDirective,
    PasswordValidationComponent,
  ],
  exports: [
    StringInputComponent,
    InputErrorsComponent,
    NumericKeypadComponent,
    AsyncButtonComponent,
    LicenseInputComponent,
    PasswordInputComponent,
    FormErrorsComponent,
    StickyHeaderFormComponent,
    SelectMenuComponent,
    SelectAutocompleteAddressComponent,
    MultiselectComponent,
    PasswordValidationComponent
  ]
})
export class AppFormsModule {
}
