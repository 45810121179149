import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../authentication/auth.service';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { Location } from '@angular/common';
import { APP_ROUTES } from 'app/app.routes';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private authStorageService: AuthStorageService,
    private location: Location) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const url = this.location.path();
          if (error.status === 419 &&
            !new RegExp(APP_ROUTES.START).test(url) &&
            !new RegExp(APP_ROUTES.NOT_FOUND).test(url) &&
            !new RegExp(APP_ROUTES.NO_CONTRACTS).test(url) &&
            !new RegExp(APP_ROUTES.USER_ERROR).test(url)) {

            return this.authService.refreshToken()
              .pipe(
                switchMap((refreshResult) => {
                  if (refreshResult) {
                    const newRequest = request.clone({
                      setHeaders: {
                        Authorization: `Bearer ${this.authStorageService.accessToken}`,
                      },
                    });
                    return next.handle(newRequest);
                  }
                  return throwError(error);
                }),
              );
          } else {
            return throwError(error);
          }
        }),
      );
  }
}
