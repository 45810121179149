import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output
} from '@angular/core';
import { AppConfigService } from '../../config/app-config.service';
import { MapService } from '../../map/map.service';
import { MapGoogleService } from '../../ui/map-google.service';

@Component({
  selector: 'app-gmap',
  templateUrl: './gmap.component.html',
  styleUrls: ['./gmap.component.scss'],
  providers: [
    MapGoogleService
  ]
})

export class GmapComponent implements AfterViewInit, OnDestroy {


  public streetViewControl = false;
  public zoomControl = false;
  public disableDefaultUI = true;
  public disableDoubleClickZoom = false;
  public mapTypeId: google.maps.MapTypeId = google.maps.MapTypeId.ROADMAP;
  public maxZoom = 19;
  public minZoom = 3;
  public zoom = 13;


  /**
   * Manual centering will prevent the map from re-centering the viewport to the user's position
   * @type {boolean}
   */
  public manualCentering = false;

  @Input()
  public scrollwheel = false;

  @Input()
  public moveEnabled: boolean;

  @Input()
  public geolocationEnabled: boolean;

  @Input()
  public controlsEnabled: boolean;

  @Output()
  public moveMap = new EventEmitter();

  @Output()
  public zoomMap = new EventEmitter();

  @Output()
  public showMyPosition = new EventEmitter();

  @Output()
  public showMessage = new EventEmitter();

  @Output()
  public geolocationError = new EventEmitter();

  private mapDiv: HTMLElement;
  private mapDivParent: any;

  constructor(public mapGoogleService: MapGoogleService,
              private appConfig: AppConfigService,
              private elementRef: ElementRef,
              private mapService: MapService,
              private ngZone: NgZone) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event) {
    const that = this;
    setTimeout(() => {
      that.mapGoogleService.resize();
    }, 10);
  }

  ngAfterViewInit() {
    this.mapDivParent = document.querySelector('.gmap-wrap');
    this.mapDiv = this.elementRef.nativeElement.querySelector('#map');
    this.createMap(this.mapDiv);

    // If the user is already geolocated, display its position
    if (this.mapService.isGeolocated()) {
      this.showMyPosition.emit(this.mapService.getStoredPosition());
    } else {
      // Else, use Google API
      this.geolocateApiGoogle();
    }
  }

  ngOnDestroy() {
    if (this.mapGoogleService) {
      this.mapGoogleService.destroy();
    }
  }

  /*
    getCoordsViewport
    return coords (visible viewport) maxLatitude/maxLongitude/minLatitude/minLongitude
   */
  getCoordsViewport(): any {

    const o = this.getMapDimensions();
    let maxLatitude;
    let maxLongitude;
    let minLatitude;
    let minLongitude;

    if (this.mapGoogleService.map.getBounds()) {
      maxLatitude = this.mapGoogleService.map.getBounds().getNorthEast().lat();
      maxLongitude = this.mapGoogleService.map.getBounds().getNorthEast().lng();
      minLatitude = this.mapGoogleService.map.getBounds().getSouthWest().lat();
      minLongitude = this.mapGoogleService.map.getBounds().getSouthWest().lng();
    }

    const diffLat = maxLatitude - minLatitude;
    const diffLong = maxLongitude - minLongitude;

    let maxLat = maxLatitude - (diffLat * (o.margin / o.hMap));
    let minLat = minLatitude + (diffLat * (o.marginBottom / o.hMap));
    let maxLong = maxLongitude;
    let minLong = minLongitude;

    if (o.wMap > o.w) {
      maxLong -= (diffLong * o.rx);
      minLong += (diffLong * o.rx);
    }

    if (o.hMap > o.h) {
      maxLat -= (diffLat * o.ry);
      minLat += (diffLat * o.ry);
    }

    return {
      maxLatitude: maxLat,
      maxLongitude: maxLong,
      minLatitude: minLat,
      minLongitude: minLong
    };

  }

  /*
    fitBounds
    params: bounds clusters(google.maps.LatLngBounds)
  */
  fitBounds(bounds: google.maps.LatLngBounds) {

    const o = this.getMapDimensions();

    const maxLatitude = bounds.getNorthEast().lat();
    const maxLongitude = bounds.getNorthEast().lng();
    const minLatitude = bounds.getSouthWest().lat();
    const minLongitude = bounds.getSouthWest().lng();

    let diffLat = maxLatitude - minLatitude;
    let diffLong = maxLongitude - minLongitude;

    let maxLat = maxLatitude;
    let minLat = minLatitude;
    let maxLong = maxLongitude;
    let minLong = minLongitude;


    if (o.wMap > o.w) {
      maxLong += (diffLong * o.rx);
      minLong -= (diffLong * o.rx);
      diffLong = maxLong - minLong;
    }

    if (o.hMap > o.h) {
      maxLat += (diffLat * o.ry);
      minLat -= (diffLat * o.ry);
      diffLat = maxLat - minLat;
    }

    const mLat = o.marginBottom * (o.hMap / o.h);
    const mLatN = o.margin * (o.hMap / o.h);
    const mLong = o.margin * (o.wMap / o.w);

    maxLat += (diffLat * (mLatN / o.h));
    minLat -= (diffLat * (mLat / o.h));
    maxLong += (diffLong * (mLong / o.w));
    minLong -= (diffLong * (mLong / o.w));

    const boundsMap = new google.maps.LatLngBounds();
    boundsMap.extend(new google.maps.LatLng(maxLat, maxLong, false));
    boundsMap.extend(new google.maps.LatLng(minLat, minLong, false));

    this.mapGoogleService.map.fitBounds(boundsMap);
  }

  /**
   * Trigger the browers native geolocation API
   */
  getCurrentMyPosition() {

    this.mapService.getCurrentPosition().forEach(
      (position: Position) => {
        const obj = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.showMyPosition.emit(obj);

      }, null
    ).then(() => {
    }).catch(
      (error: string) => {
        this.showMessage.emit(error);
        this.geolocationError.emit(error);
        // If native geolocation fails, use Google API instead
        this.mapService.geolocateApiGoogle().subscribe(
          position => {
            const obj = {
              lat: position.location.lat,
              lng: position.location.lng
            };
            this.mapGoogleService.myLongitude = position.location.lng;
            this.mapGoogleService.myLatitude = position.location.lat;
            this.showMyPosition.emit(obj);

          });
      }
    );
  }

  /* Creates the map with the set properties.*/
  private createMap(el: HTMLElement) {

    this.mapGoogleService.initMap(el, {

      center: new google.maps.LatLng(this.mapGoogleService.myLatitude, this.mapGoogleService.myLongitude, false),
      disableDefaultUI: this.disableDefaultUI,
      disableDoubleClickZoom: this.disableDoubleClickZoom,
      mapTypeId: this.mapTypeId,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      scrollwheel: this.scrollwheel,
      // styles: this.styles,
      zoom: this.zoom,
      streetViewControl: this.streetViewControl,
      zoomControl: this.zoomControl
    });

    if (this.moveEnabled) {
      this.initMoveMap();
    }
  }

  /* drag/zoom map.*/
  private initMoveMap() {

    let c = {maxLatitude: 0, maxLongitude: 0, minLatitude: 0, minLongitude: 0};

    this.mapGoogleService.map.addListener('idle', () => {
      this.ngZone.run(() => {

        const coords = this.getCoordsViewport();

        if (coords.maxLongitude === c.maxLongitude &&
          coords.minLongitude === c.minLongitude &&
          coords.maxLatitude === c.maxLatitude &&
          coords.minLatitude === c.minLatitude) {
          return;
        } else {
          this.moveMap.emit(coords);
        }

        c = coords;

      });
    });

    this.mapGoogleService.map.addListener('zoom_changed', () => {
      this.ngZone.run(() => {
        this.zoomMap.emit(null);
      });
    });

  }

  /* getMapDimensions */
  private getMapDimensions(): any {

    const ww = window.innerWidth;
    const wMap = this.mapDiv.clientWidth;
    const hMap = this.mapDiv.clientHeight;
    const h = this.mapDivParent.clientHeight;
    const w = this.mapDivParent.clientWidth;

    const obj = {
      wMap: wMap,
      hMap: hMap,
      h: h,
      w: w,
      margin: 40, // To avoid seeing a small part of Pin
      marginBottom: (ww > this.appConfig.config.breakpoints.m) ? 180 : 115, // push proximity height or push results height
      rx: ((wMap - w) / 2) / wMap,
      ry: ((hMap - h) / 2) / hMap
    };

    return obj;
  }

  /**
   * Geolocate the user via Google's REST api. Coordinates are approximate
   * Unless manualCentering is set to true, the viewport will be centered on these coordinates
   */
  private geolocateApiGoogle() {

    this.mapService.getCurrentPosition().forEach((position: Position) => {
      this.setMyPosition(position.coords.latitude, position.coords.longitude);
    }).catch((error) => {
      console.log('GPS get current position error', error);

      this.mapService.geolocateApiGoogle().subscribe(
        position => {
          this.setMyPosition(position.location.lat, position.location.lng, false);
        });
    });
  }

  private setMyPosition(lat, lng, showMarker = true) {
    this.mapGoogleService.myLongitude = lng;
    this.mapGoogleService.myLatitude = lat;
    if (!this.manualCentering) {
      this.mapGoogleService.setCenter(new google.maps.LatLng(lat, lng, false));
      this.mapGoogleService.resize();
    }
    if (showMarker) {
      this.showMyPosition.emit({lat: lat, lng: lng});
    }
  }

}
