import { Driver } from '../driver/shared/models/driver.model';
import { DriverService } from '../driver/shared/driver.service';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import 'rxjs/add/operator/delay';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { MaskComponent } from '../shared/mask/mask.component';
import { Router } from '@angular/router';
import { PopinService } from '../shared/ui/popin.service';
import { ModalService } from '../shared/ui/modal.service';
import ModalCode from '../shared/modal-box/ModalCode';
import { AppStorageService } from '../shared/storage/common/app-storage.service';
import { Subscription } from 'rxjs';
import { CookiesService } from 'app/shared/cookies/cookies.service';
import { TranslationService } from 'app/shared/translation/translation.service';
import { environment } from 'environments/environment';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';

@Component({
  selector: 'app-more-menu',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
  animations: [
    trigger('toggle', [
      state('shown', style({ transform: 'translate3d(0,0,0)' })),
      transition('hidden => *', [
        animate('300ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translate3d(0,0,0)' }))
      ]),
      transition('* => shown', [
        animate('300ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translate3d(0,0,0)' }))
      ]),
      transition('shown => hidden', [
        animate('300ms cubic-bezier(0.5, 0.130, 0.5, 0.950)', style({ transform: 'translateX(100%)' }))
      ]),
    ])
  ]
})
export class MoreMenuComponent implements OnInit, OnDestroy {

  public stateToggle = '';
  public modalSubscriptionFavApp: Subscription;
  public logOutPopin = false;
  // tslint:disable-next-line:no-output-rename
  @Output('endAnimationClose')
  public endAnimationClose = new EventEmitter();
  @ViewChild(MaskComponent)
  public mask;
  driver: Driver;
  public appUrlToGo: string = null;
  private subscription: Subscription;
  public readonly GTM_EVENTS = GTM_EVENTS;

  constructor(private driverService: DriverService,
    private router: Router,
    private popinService: PopinService,
    private modalService: ModalService,
    private storage: AppStorageService,
    private cookiesService: CookiesService,
    private translationService: TranslationService) {

    this.driverService.driverStream.subscribe(
      driver => {
        this.driver = driver;
      }
    );
    this.listenToModalFavApp();
  }

  ngOnInit() {
    this.popinService.opened(null);
    this.subscription = this.popinService.getOpened().subscribe(
      results => {
        if (results !== null) {
          if (results) {
            this.stateToggle = 'shown';
            this.mask.show = true;
          } else {
            this.stateToggle = 'hidden';
            this.mask.show = false;
          }
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.modalSubscriptionFavApp.unsubscribe();
  }

  animationDone($event) {
    if ($event.toState === 'hidden') {
      this.router.navigate(['/more']);
    }
  }

  onClosePopin() {
    this.popinService.opened(false);
  }

  openModal(event) {
    event.preventDefault();
    this.modalService.modalCode = ModalCode.LOGOUT;
    this.modalService.openModal('global-logout_popin-text', 'global-logout_popin-title');
  }

  listenToModalFavApp() {
    this.modalSubscriptionFavApp = this.modalService.modalActionObservable.subscribe(
      status => {
        if (status === true && this.modalService.modalCode === ModalCode.FAVOURITE_APP) {
          this.modalService.reset();
          window.open(this.appUrlToGo, '_blank');
        }
      }
    );
  }



  getCookiesPolicyLink() {
    return this.cookiesService.getCookiesPolicyDocumentUrl(this.translationService.language);
  }

  getTermsOfUseLink() {
    return `${environment.publicDocumentsBaseUrl}/${this.storage.countrySelected}/disclaimer-${this.translationService.language}.pdf`;
  }
}
