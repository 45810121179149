import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/publishLast';
import 'rxjs/add/operator/catch';
import { Observable, throwError } from 'rxjs';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { TransferHttpOptions } from './transfer-http-options';
import { HttpConst } from '../helper/http.const';
import { AppStorageService } from '../storage/common/app-storage.service';

@Injectable()
export class ApiService {
  public contentType = 'application/json';
  public formData = false;

  constructor(
    private storageService: AppStorageService,
    private httpClient: HttpClient,
    private authStorageService: AuthStorageService) {
  }


  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.set('Authorization', `Bearer ${this.authStorageService.accessToken}`);
    headers.set('Content-Type', `${this.contentType}`);
    return headers;
  }

  public get<T>(url: string, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.get<T>(url, this.getOptions(isAnonymousCall));
  }

  public post<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.post<T>(url, body, this.getOptions(isAnonymousCall));
  }

  public postFile<T>(url: string, body: any): Observable<any> {
    return this.httpClient.post<T>(url, body, this.getOptions(false, false));
  }

  public putFile<T>(url: string, body: any): Observable<any> {
    return this.httpClient.put<T>(url, body, this.getOptions(false, false));
  }

  public put<T>(url: string, body?: any, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.put<T>(url, body, this.getOptions(isAnonymousCall));
  }

  public delete<T>(url: string, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.delete<T>(url, this.getOptions(isAnonymousCall));
  }

  public patch<T>(url: string, body: any, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.patch<T>(url, body, this.getOptions(isAnonymousCall));
  }

  public head<T>(url: string, isAnonymousCall: Boolean = false): Observable<any> {
    return this.httpClient.head<T>(url, this.getOptions(isAnonymousCall));
  }

  private getOptions(isAnonymousCall: Boolean, useJsonContentType = true): TransferHttpOptions {
    let options: TransferHttpOptions;
    let headers: HttpHeaders;

    if (isAnonymousCall) {
      headers = new HttpHeaders({
        'Content-Type': `${this.contentType}`
      });
    } else {
      headers = new HttpHeaders({
        'Authorization': `Bearer ${this.authStorageService.accessToken}`,
        'Content-Type': `${this.contentType}`
      });
      headers = headers.append(HttpConst.headers.CountryCode, this.storageService.countrySelected);
      headers = headers.append(HttpConst.headers.UserRole, this.storageService.currentUserRole);
    }

    options = { headers };

    return options;
  }
}
