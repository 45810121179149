export const COUNTRIES = {
  Romania: {
    country_code: 'RO',
    default_lang: 'en-us',
    langs: ['ro-ro', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  Turkey: {
    country_code: 'TR',
    default_lang: 'tr-tr',
    langs: ['tr-tr', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  Ukraine: {
    country_code: 'UA',
    default_lang: 'uk-ua',
    langs: ['uk-ua', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  India: {
    country_code: 'IN',
    default_lang: 'en-us',
    langs: ['en-us'],
    company_logo : 'brand-logo.svg',
  },
  Bulgaria: {
    country_code: 'BG',
    default_lang: 'bg-bg',
    langs: ['bg-bg', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  Greece: {
    country_code: 'GR',
    default_lang: 'el-gr',
    langs: ['el-gr', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  Austria: {
    country_code: 'AT',
    default_lang: 'en-us',
    langs: ['de-de', 'en-us'],
    company_logo : 'brand-logo.svg',
  },
  Switzerland: {
    country_code: 'CH',
    default_lang: 'en-us',
    langs: ['de-de', 'en-us', 'fr-fr', 'it-it'],
    company_logo : 'brand-logo.svg',
  },
  Hungary: {
    country_code: 'HU',
    default_lang: 'en-us',
    langs: ['en-us', 'hu-hu'],
    company_logo : 'brand-logo.svg',
  },
  Serbia: {
    country_code: 'RS',
    default_lang: 'en-us',
    langs: ['en-us', 'sr-rs'],
    company_logo : 'brand-logo.svg',
  },
  Algeria: {
    country_code: 'DZ',
    default_lang: 'en-us',
    langs: ['en-us', 'fr-fr'],
    company_logo : 'brand-logo.svg',
  },
  Morocco: {
    country_code: 'MA',
    default_lang: 'en-us',
    langs: ['en-us', 'fr-fr'],
    company_logo : 'brand-logo.svg',
  },
  Poland: {
    country_code: 'PL',
    default_lang: 'en-us',
    langs: ['en-us', 'pl-pl'],
    company_logo : 'brand-logo.svg',
  },
  Finland: {
    country_code: 'FI',
    default_lang: 'en-us',
    langs: ['en-us'],
    company_logo : 'brand-logo.svg',
  },
  Slovenia: {
    country_code: 'SI',
    default_lang: 'en-us',
    langs: ['en-us', 'sl-si'],
    company_logo : 'brand-logo.svg',
  },
  Croatia: {
    country_code: 'HR',
    default_lang: 'en-us',
    langs: ['en-us', 'hr-hr'],
    company_logo : 'brand-logo.svg',
  },
  Portugal: {
    country_code: 'PT',
    default_lang: 'pt-pt',
    langs: ['en-us', 'pt-pt'],
    company_logo : 'brand-logo.svg',
  },
  Estonia: {
    country_code: 'EE',
    default_lang: 'en-us',
    langs: ['en-us', 'et-ee'],
    company_logo : 'brand-logo.svg',
  },
  Latvia: {
    country_code: 'LV',
    default_lang: 'en-us',
    langs: ['en-us', 'lv-lv'],
    company_logo : 'brand-logo.svg',
  },
  Lithuania: {
    country_code: 'LT',
    default_lang: 'en-us',
    langs: ['en-us', 'lt-lt'],
    company_logo : 'brand-logo.svg',
  },
  Nffleet: {
    country_code: 'NFFLEET',
    default_lang: 'en-us',
    langs: ['en-us'],
    company_logo : 'logo-nffleet-white.png',
  },
  Malaysia: {
    country_code: 'MY',
    default_lang: 'en-us',
    langs: ['en-us'],
    company_logo : 'logo-ALD-mitsubishi-2021_logos white.png',
  }
};
