import { Component , OnInit} from '@angular/core';
import ModalCode from '../../modal-box/ModalCode';
import { ModalService } from '../../ui/modal.service';
import { AppStorageService } from '../../storage/common/app-storage.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../authentication/auth.service';
import { ConfigurationService } from '../../config/configuration.service';
import { TranslationService } from '../../translation/translation.service';
import { TranslationStorageService } from '../../storage/translation/translation-storage.service';
import { contractsContainsStatusOfType } from '../../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../../driver/shared/contract/contract-status.const';
import { DriverService } from '../../../driver/shared/driver.service';
import { Router } from '@angular/router';
import { UserManagementService } from '../../user/userManagement.service';
import { PreferencesModel } from '../my-preferences/preferences.model';
import { FeedService } from '../../../feed/shared/feed.service';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { UserService } from 'app/shared/user/user.service';
import { AppConfigService } from 'app/shared/config/app-config.service';
import { ConfigurationModel } from 'app/shared/config/configuration.model';
import { AuthStorageService } from 'app/shared/storage/auth/auth-storage.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {

  public logoutIframe;
  public availableLanguages = [];
  public selectedLanguage: string;
  public hasDriverActiveContract = false;
  public isProspect = false;
  public isFleetManager = false;
  public isMobile: boolean;
  public fleetManagerLink: string;
  public showProfileDropdown: boolean;
  public showLanguageDropdown: boolean;

  constructor(private modalService: ModalService,
              private driverService: DriverService,
              private authService: AuthService,
              private feedService: FeedService,
              private storage: AppStorageService,
              private translationStorage: TranslationStorageService,
              private translationService: TranslationService,
              private router: Router,
              private userService: UserService,
              private appConfig: AppConfigService,
              private authStorageService: AuthStorageService,
              private userManagementService: UserManagementService,
              private configurationService: ConfigurationService,
              private gtmService: GtmService) { }

  ngOnInit() {
    this.listenToModal();
    this.selectedLanguage = this.translationStorage.lang;

    this.configurationService.getAvailableLanguages().subscribe((languages) => {
      this.availableLanguages = languages;
    });
    this.driverService.driver.subscribe((driver) => {
      this.isProspect = driver.roles.every(role => role === 'prospect');
      if (driver && contractsContainsStatusOfType(driver.contracts, ContractConsolidatedStatusType.Active)) {
        this.hasDriverActiveContract = true;
      }
    });
    this.isFleetManager = this.userService.getRoles().includes('FleetManager') ||
    this.userService.getRoles().includes('fleetmanager');
    this.isMobile = this.appConfig.isMobile;
    this.getFleetManagerLink('ManagerWebsiteUrl');
  }

  public openModal(): void {
    event.preventDefault();
    this.modalService.modalCode = ModalCode.LOGOUT;
    this.modalService.openModal('global-logout_popin-text', 'global-logout_popin-title');
  }

  public openAssistancePanel(): void {
    if (!this.router.url.includes('/help') && !this.router.url.includes('/assistance')) {
      const url = this.hasDriverActiveContract && !this.isProspect ? ['/help'] : ['/assistance'];
      this.router.navigate([this.router.url + url]);
      this.showProfileDropdown = false;
    }
  }

  public openMyProfilePanel(): void {
    if (!this.router.url.includes('/personal-informations')) {
      this.router.navigate([this.router.url + '/personal-informations']);
      this.showProfileDropdown = false;
    }
  }

  public openMyPreferencesPanel() {
    if (!this.router.url.includes('/preferences')) {
      this.router.navigate([this.router.url + '/preferences']);
      this.showProfileDropdown = false;
    }
  }

  public getFleetManagerLink(fleetManagerLinkCode: string): void {
    this.configurationService.getConfItems([this.userService.getCountry(), fleetManagerLinkCode])
      .subscribe(
        (feedback: ConfigurationModel) => {
          const { value } = feedback;
          this.fleetManagerLink = value;
        },
        e => {
          console.error('An error has occured: ', e);
        });
  }

  public setAppStorage(): void {
    this.authStorageService.preferredRole = 'fleetmanager';
  }

  public listenToModal(): void {
    this.modalService.modalActionObservable.subscribe(
      status => {
        if (status === true && this.modalService.modalCode === ModalCode.LOGOUT) {
          this.logOut();
          this.storage.remove(this.storage.SELECTED_COUNTRY);
          this.modalService.reset();
        }
      }
    );
  }

  public logOut(): void {
    window.addEventListener('message', (e: any) => {
      const { data: eventData } = e;
      if (eventData.waitingForLogin) {
        this.gtmService.sendEvent(GTM_EVENTS.logout, this.storage.currentUserRole, this.storage.countrySelected);
        this.storage.remove(this.storage.CURRENT_ROLE, AppStorageService.STORES.SESSION);
        this.storage.remove(this.storage.SELECTED_COUNTRY, AppStorageService.STORES.SESSION);
        this.authService.clearAuthentication(false);
        (document as any).location = '/';
      }
    }, false);
    this.logoutIframe = document.getElementById('logout_ifm') as HTMLIFrameElement;
    this.logoutIframe.src = (environment.envName === 'DEV') ?
      'https://driver.integ.aldautomotive.com/logout' :
      '/logout';
  }

  public onLanguageChange(selectedLanguage): void {
    this.translationService.language = selectedLanguage;
    this.selectedLanguage = selectedLanguage;
    this.showLanguageDropdown = false;
    const preferences: PreferencesModel = { culture: selectedLanguage };
    this.userManagementService.updateMyUserPreferences(preferences)
      .subscribe(() => {
        if (this.router.url.includes('feed')) {
         this.feedService.loadFeeds();
        }
      });
  }
}
