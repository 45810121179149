import { UserManagementService } from 'app/shared/user/userManagement.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppInsightsService } from 'ng2-appinsights';
import { environment } from '../environments/environment';
import { ModalInput } from './shared/modal-box/modal-input.model';
import { Subscription } from 'rxjs';
import { COUNTRIES } from './shared/translation/translation.settings';
import { ModalService } from './shared/ui/modal.service';
import { AuthService } from './shared/authentication/auth.service';
import { AppStorageService } from './shared/storage/common/app-storage.service';
import { AppConfigService } from 'app/shared/config/app-config.service';
import { SvgSpriteService } from './shared/ui/svg-sprite.service';
import { flattenRouteData } from './shared/helper/utils/flatten-route-data';
import ModalCode from './shared/modal-box/ModalCode';
import { AuthConst } from './shared/authentication/auth.const';
import { FeatureService } from './shared/feature/feature.service';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { APP_ROUTES } from './app.routes';
import { TranslationService } from './shared/translation/translation.service';
import { HostPageService } from './shared/config/host-page.service';
import { filter, tap, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private authUserMenu = false;
  private routeUserMenuFlag = false;

  private countrySelectorInput: ModalInput;

  public get displayUserMenu(): boolean {
    return this.routeUserMenuFlag && this.authUserMenu;
  }

  public modalSubscription: Subscription;
  private countriesList = COUNTRIES;

  constructor(private authService: AuthService,
    private appStorageService: AppStorageService,
    private translationService: TranslationService,
    private appConfig: AppConfigService,
    private svgSprite: SvgSpriteService,
    private hostPage: HostPageService,
    private router: Router,
    private userManagementService: UserManagementService,
    private appinsightsService: AppInsightsService,
    private modalService: ModalService,
    private featureService: FeatureService,
    private gtmService: GtmService) {
  }

  ngOnInit() {
    this.selectCountry();
    this.initializeSvgSprite();
    this.initializeAppinsighs();
    this.displayAuthenticatedUserMenu();
    this.checkNavigation();
    this.featureService.updateFeaturesList();
  }

  private selectCountry() {
    if (this.appStorageService.countrySelected) {
      const mapScript = document.createElement('script');
      const googleMapCountry = this.appStorageService.countrySelected.toUpperCase() === 'NFFLEET' ? 'FI' : this.appStorageService.countrySelected.toUpperCase();
      mapScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.gmapApiKey}&libraries=places&region=${googleMapCountry}`;
      document.body.appendChild(mapScript);
      this.translationService.translateService.onLangChange.subscribe(() => {
        this.hostPage.hideLoadingScreen();
      });
    };
    if (!this.appStorageService.countrySelected && !environment.production) {
      this.openSelectCountryModal();
    }
  }

  private openSelectCountryModal() {
    this.modalService.modalCode = ModalCode.COUNTRY_SELECT;
    this.countrySelectorInput = new ModalInput('countries');
    const countriesKeys = Object.keys(this.countriesList);
    this.countrySelectorInput.data = countriesKeys.map(country => {
      return { label: country, value: this.countriesList[country].country_code };
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.modalService.openModal(
      'country-selector',
      'Choose your country',
      null,
      'Save',
      null,
      this.countrySelectorInput
    );

    this.modalSubscription = this.modalService.modalActionObservable.subscribe(
      status => {
        if (status === true && this.modalService.modalCode === ModalCode.COUNTRY_SELECT) {
          this.appStorageService.countrySelected = this.modalService.modalValue[0].value;
          this.modalService.reset();
          window.location.reload();
        }
      }
    );
  }

  private initializeSvgSprite() {
    this.svgSprite.load(`${this.appConfig.config.assetsPath}/images/svg-sprite.svg`);
  }

  private initializeAppinsighs() {
    this.appinsightsService.Init({
      instrumentationKey: environment.appInsightInstrumentationKey,
      verboseLogging: true,
      disableCorrelationHeaders: false
    });
  }

  private displayAuthenticatedUserMenu() {
    this.authUserMenu = false;
    this.authService.authStatus$.pipe(
      filter(authStatus => authStatus !== AuthConst.status.LOGGED_OUT),
      tap(() => this.authUserMenu = true),
      take(1),
      switchMap(() => this.userManagementService.updateLastLoginDate()),
    ).subscribe();
  }

  private checkNavigation() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { // Only react when the navigation ends
        if (/Trident\/|MSIE/.test(window.navigator.userAgent) && this.appStorageService.countrySelected) {
          this.modalService.modalCode = ModalCode.UNSUPPORTED_BROWSER;
          this.modalService.openModal('global-browser_version_popin-text', 'global-browser_version_popin-title');
        }
        const routeData = flattenRouteData(this.router.routerState.root.snapshot);
        // Don't track in start, error page & non existing routes
        if (
          !new RegExp(APP_ROUTES.START).test(event.url) &&
          !new RegExp(APP_ROUTES.NOT_FOUND).test(event.url) &&
          !new RegExp(APP_ROUTES.NO_CONTRACTS).test(event.url) &&
          !new RegExp(APP_ROUTES.USER_ERROR).test(event.url) &&
          event.url === event.urlAfterRedirects
        ) {
          this.gtmService.sendPageView(event.url);
        }
        this.routeUserMenuFlag = routeData.userMenu;
      }
    });
  }
}
