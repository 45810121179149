﻿import { MaterialModule } from '../ui/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { SharedModule } from '../../shared/shared.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { NoContractPageComponent } from './no-contract-page/no-contract-page.component';
import { UserErrorPageComponent } from './user-error/user-error-page.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    PipesModule,
    TranslateModule,
    MaterialModule,
  ],
  declarations: [
    PageNotFoundComponent,
    MaintenanceComponent,
    NoContractPageComponent,
    UserErrorPageComponent],
  exports: [
    MaintenanceComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})

export class ErrorsModule {
}
