export const LANGUAGES_SETTINGS = {
  'RO': {
    'name': 'română',
    'country_code': 'RO',
    'phoneFormat': '+40',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'ro-ro': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'UA': {
    'name': 'ukrainian',
    'country_code': 'UA',
    'phoneFormat': '+380',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'uk-ua': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'TR': {
    'name': 'turkiska',
    'phoneFormat': '+90',
    'country_code': 'TR',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'tr-tr': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'IN': {
    'name': 'india',
    'phoneFormat': '+91',
    'country_code': 'IN',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'BG': {
    'name': 'bulgaria',
    'phoneFormat': '+359',
    'country_code': 'BG',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'bg-bg': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'GR': {
    'name': 'greece',
    'phoneFormat': '+30',
    'country_code': 'GR',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'el-gr': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'AT': {
    'name': 'austria',
    'phoneFormat': '+43',
    'country_code': 'AT',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'de-de': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'CH': {
    'name': 'switzerland',
    'phoneFormat': '+41',
    'country_code': 'CH',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'de-de': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'fr-fr': {
        'dM': 'd MMMM Y',
        'slashDate': 'DD/MM/YYYY',
      },
      'it-it': {
        'dM': 'd MMMM Y',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'HU': {
    'name': 'hungary',
    'phoneFormat': '+36',
    'country_code': 'HU',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'hu-hu': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'RS': {
    'name': 'serbia',
    'phoneFormat': '+381',
    'country_code': 'RS',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'sr-rs': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'DZ': {
    'name': 'algeria',
    'phoneFormat': '+213',
    'country_code': 'DZ',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'fr-fr': {
        'dM': 'd MMMM',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'MA': {
    'name': 'morocco',
    'phoneFormat': '+212',
    'country_code': 'MA',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'fr-fr': {
        'dM': 'd MMMM Y',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'PL': {
    'name': 'poland',
    'phoneFormat': '+48',
    'country_code': 'PL',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'pl-pl': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'FI': {
    'name': 'finland',
    'phoneFormat': '+358',
    'country_code': 'FI',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'SI': {
    'name': 'slovenia',
    'phoneFormat': '+386',
    'country_code': 'SI',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'sl-si': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'HR': {
    'name': 'croatia',
    'phoneFormat': '+385',
    'country_code': 'HR',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'hr-hr': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'PT': {
    'name': 'portugal',
    'phoneFormat': '+351',
    'country_code': 'PT',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      },
      'pt-pt': {
        'dM': 'D MMMM',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'EE': {
    'name': 'estonia',
    'phoneFormat': '+372',
    'country_code': 'EE',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD.MM.YYYY',
      },
      'et-ee': {
        'dM': 'MMMM Do',
        'slashDate': 'DD.MM.YYYY',
      },
    }
  },
  'LV': {
    'name': 'latvia',
    'phoneFormat': '+371',
    'country_code': 'LV',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD.MM.YYYY',
      },
      'lv-lv': {
        'dM': 'MMMM Do',
        'slashDate': 'DD.MM.YYYY',
      },
    }
  },
  'LT': {
    'name': 'lithuania',
    'phoneFormat': '+370',
    'country_code': 'LT',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'YYYY-MM-DD',
      },
      'lt-lt': {
        'dM': 'MMMM Do',
        'slashDate': 'YYYY-MM-DD',
      },
    }
  },
  'NFFLEET': {
    'name': 'nordeafinancefleet',
    'phoneFormat': '+358',
    'country_code': 'NFFLEET',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
  'MY': {
    'name': 'malaysia',
    'phoneFormat': '+60',
    'country_code': 'MY',
    'cultures': {
      'en-us': {
        'dM': 'MMMM Do',
        'slashDate': 'DD/MM/YYYY',
      }
    }
  },
};
