export const environment = {
  production: false,
  envName: 'INT',
  gmapApiKey: 'AIzaSyBZjCDTWi91kaU_A_j1gWl4JI1uUlJvQ4o',
  apigatewayUrl: 'https://apidriver.integ.aldautomotive.com',
  adfsUrl: 'https://integ.authenticationportal.com/adfs/oauth2/authorize?response_type=code&client_id=77f58e4f-39a1-4407-8fd7-b0718da51658&resource=https://driver.integ.aldautomotive.com&redirect_Uri=https://driver.integ.aldautomotive.com/onlogin',
  translateLoaderPrefix: '/apigateway/Translation/api/Translations/myalddriver/',
  appInsightInstrumentationKey: '9ce87323-ee2b-4b64-a568-76cbccf4ddab',
  assetsPath: '/assets/ald',
  siteKey: '6LcvoMUUAAAAAEMoR7cW-WbTWjyEPyXqlgqkYrOx',
  publicDocumentsBaseUrl: 'https://myalddocumentsint.blob.core.windows.net/public',
  gtmKey: 'GTM-56DPNWF',
  didomiApiKey: 'cf2c3525-195b-489b-9bea-b733850b94b6'
};
