import {
  Component,
  ElementRef,
  forwardRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  Optional,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordInputComponent),
      multi: true
    }
  ]
})
export class PasswordInputComponent implements ControlValueAccessor {

  @Input()
  public placeholder: string;

  @Input()
  public useNumericKeypad = false;

  @Input() public required = false;

  @ViewChild('input')
  public inputElement: ElementRef;

  @ViewChild('keypad')
  public keypadElement: ElementRef;

  // proxy ngModel for the input field
  public model: any;

  public inputType = 'password';

  @HostBinding('class.pad-open')
  showNumericKeypad = false;
  /**
   * This callback is called when the value has changed
   */
  private onChangeCallback: (_: any) => {};

  constructor(@Host() @Optional() private ngForm: NgForm, // get form from parent component
              @Host() @Optional() private formGroupDir: FormGroupDirective // get form from parent component
  ) {

  }

  get form() {
    return this.ngForm || this.formGroupDir;
  }

  /**
   * Listen to document clicks to close the numeric keypad
   * @param $event
   */
  @HostListener('document:click', ['$event'])
  onDocumentClick($event) {
    this.showNumericKeypad = false;
  }

  updateValue(value: any) {
    this.model = value;
    this.onChangeCallback(this.model);
  }

  showPassword() {
    this.inputType = 'text';
  }

  hidePassword() {
    this.inputType = 'password';
  }

  togglePassword($event) {
    $event.stopPropagation();
    this.inputType = (this.inputType === 'password') ? 'text' : 'password';
  }

  onFocus() {
    this.showNumericKeypad = true;
  }

  onInputClick($event) {
    $event.stopPropagation();
  }

  onKeypadChange($event) {
    this.updateValue($event);
  }

  onKeypadSubmit() {
    // Trigger form submission manually
    this.form.onSubmit(null);

    this.showNumericKeypad = false;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.model = obj.toString();
    } else {
      this.model = null;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    // TODO implement this
  }

}
