const ModalCode = {
  LOGOUT: 'logout',
  RESETPWD: 'resetpwd',
  FAVOURITE_APP: 'favouriteapp',
  GEOLOCATION_ERR: 'geolocationerror',
  ENTER_PHONE: 'enterphonenumber',
  DOWNLOAD_APP: 'downloadapp',
  COUNTRY_SELECT: 'country',
  PASSWORD_EXPIRATION: 'passwordexpiration',
  UNSUPPORTED_BROWSER: 'unsupportedbrowser',
};

export default ModalCode;
