import { Component, HostListener, OnInit } from '@angular/core';
import { AppConfigService } from '../../config/app-config.service';
import { AuthService } from 'app/shared/authentication/auth.service';
import { environment } from '../../../../environments/environment';
import { AppStorageService } from 'app/shared/storage/common/app-storage.service';

@Component({
  selector: 'app-no-contract-page',
  templateUrl: './no-contract-page.component.html',
  styleUrls: ['../page-not-found/page-not-found.component.scss']
})
export class NoContractPageComponent implements OnInit {

  public logoutIframe;
  public isDesktop = false;
  public isLoadedInsideIFrame: boolean = window.self !== window.top;

  constructor(
    private appConfig: AppConfigService,
    private authService: AuthService,
    private storage: AppStorageService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);
  }

  ngOnInit() {
    this.onResize();
    this.logOut();
  }

  public logOut(): void {
    window.addEventListener('message', (e: any) => {
      const { data: eventData } = e;
      if (eventData.waitingForLogin) {
        this.storage.remove(this.storage.CURRENT_ROLE, AppStorageService.STORES.SESSION);
        this.authService.clearAuthentication(false);
      }
    }, false);

    this.logoutIframe = document.getElementById('logout_ifm') as HTMLIFrameElement;
    this.logoutIframe.src = (environment.envName === 'DEV') ?
      'https://driver.integ.aldautomotive.com/logout' :
      '/logout';
  }
}
