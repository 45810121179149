import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopinService } from '../../shared/ui/popin.service';
import { Location } from '@angular/common';
import { HelpService } from '../shared/help.service';
import { DriverService } from '../../driver/shared/driver.service';
import { DateSortingService } from '../../shared/helper/date-sorting.service';
import { UserProfile } from '../../driver/shared/models/user-profile.model';

@Component({
  selector: 'app-help-follow-requests',
  templateUrl: './help-follow-requests.component.html',
  styleUrls: ['./help-follow-requests.component.scss']
})
export class HelpFollowRequestsComponent implements OnInit, OnDestroy {
  public newUserProfile: UserProfile;

  public vehicleList = [];
  public vehicleSelected = 0;
  public hasError: boolean;
  public contractIdList = [];

  public cases: any[];
  public loadingCases: boolean;

  constructor(
    private location: Location,
    private popinService: PopinService,
    private helpService: HelpService,
    private driverService: DriverService,
    private dateSorting: DateSortingService) {
  }

  ngOnInit() {
    this.popinService.opened(true);
    this.getDriver();
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  onBackClick() {
    this.location.back();
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.getCases();
      });
  }

  private getCases() {
    this.loadingCases = true;
    this.cases = [];

    this.helpService.getCaseHistory()
      .subscribe(cases => {
        this.hasError = false;
        if (cases) {
          this.cases = cases
            .sort(this.dateSorting.dateSortDesc)
            .map(request => this.addLicensePlateToCase(request));
        }
        this.loadingCases = false;
      }, () => {
        this.hasError = true;
        this.loadingCases = false;
      }, () => {
        this.loadingCases = false;
      });
  }

  private addLicensePlateToCase(request) {
    this.vehicleList.forEach(vehicle => {
      if (request.contractId && request.contractId === vehicle.id) {
        request.licensePlate = vehicle.licensePlate;
      }
    });
    return request;
  }

  private generateVehicleList() {
    this.driverService.getNewUserRole(this.newUserProfile).contracts.forEach((contract) => {
      const vehicle = {
        id: contract.reference,
        label: contract.vehicle.brand,
        licensePlate: contract.vehicle.plate
      };
      this.vehicleList.push(vehicle);
    });
  }
}
