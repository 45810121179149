﻿import { FeatureService } from '../../shared/feature/feature.service';
import { FeatureConst } from '../../shared/feature/feature.consts';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopinService } from '../../shared/ui/popin.service';
import { HelpService } from '../shared/help.service';
import { HelpConst } from '../shared/help.const';
import { SelectMenuComponent } from '../../shared/app-forms/select-menu/select-menu.component';
import { TranslateService } from '@ngx-translate/core';
import { ContactRequest } from '../shared/models/create-case-request.model';
import { DriverService } from '../../driver/shared/driver.service';
import { contractIsStatusOfType } from '../../driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from '../../driver/shared/contract/contract-status.const';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';

@Component({
  selector: 'app-help-contact-us',
  templateUrl: './help-contact-us.component.html',
  styleUrls: ['./help-contact-us.component.scss']
})
export class HelpContactUsComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public datasCategorySelectMenu = [];
  public submitted = false;
  public isAppointmentRequestSelected: Boolean = false;
  public datasSubjectSelectMenu = [];
  public isMaintenanceFeatureActivated: boolean;
  public newUserProfile: UserProfile;
  public vehicleList = [];
  public vehicleSelected = 0;
  public isLoading = false;

  @ViewChild('subjectField') subjectField: SelectMenuComponent;
  @ViewChild('category') category: SelectMenuComponent;

  public error = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private popinService: PopinService,
    private helpService: HelpService,
    private translateService: TranslateService,
    private featureService: FeatureService,
    private snackBarService: SnackbarService,
    private driverService: DriverService) {
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.isLoading = true;
    const category = this.form.get('category').value;
    const subject = this.form.get('subject') ? this.form.get('subject').value : { id: null, label: null };
    const details = this.form.get('details').value;

    this.translateService.get(subject.label ? [category, subject.label] : category)
      .subscribe(translations => {
        let categoryParam;
        if (subject.label) {
          subject.label = translations[subject.label];
          categoryParam = translations[category];
        } else {
          categoryParam = translations;
        }

        const contactAldParams: ContactRequest = {
          contractId: this.form.get('vehicle').value,
          category: categoryParam,
          subject: subject.id,
          details: details,
          culture: '',
        };

        this.helpService.contactAld(contactAldParams).subscribe(
          () => {
            this.isLoading = false;
            this.snackBarService.success('help-contact_us_panel-form_success');
            this.onBackClick();
          },
          error => {
            this.isLoading = false;
            if (error.status && error.status.toString().substring(0, 1) === '4') {
              this.error = 400;
            } else {
              this.error = true;
            }
          }
        );
      });
  }

  generateFormGroup() {
    this.form = this.formBuilder.group({
      vehicle: ['', Validators.required],
      details: ['', Validators.required],
      category: ['', Validators.required],
      subject: ['']
    });
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.generateFormGroup();
      });
  }

  public updateVehicleList(obj): void {
    this.form.get('vehicle').setValue(obj.id);
  }

  private generateVehicleList(): void {
    this.vehicleList = this.driverService.getNewUserRole(this.newUserProfile).contracts.filter((contract: UserRoleContract) => {
      return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
    }).map((contract: UserRoleContract) => {
      return {
        id: contract.id,
        label: `${contract.vehicle.brand} ${contract.vehicle.plate}`
      };
    });

    if (this.vehicleList.length === 1) {
      this.vehicleSelected = 0;
    }
  }

  ngOnInit() {
    this.getDriver();
    this.featureService
      .isFeatureActivated(FeatureConst.features.MaintenanceRequest).subscribe((value) => {
        this.isMaintenanceFeatureActivated = value;
      });
    this.popinService.opened(true);
    this.helpService.getCategories()
      .subscribe(categories => {
        this.datasCategorySelectMenu = categories.map((category) => {
          category.label = 'help-categories_and_subjects-' + category.label;
          category.subjects = category.subjects.map((subject) => {
            subject.label = 'help-categories_and_subjects-' + subject.label;
            return subject
          })
          return category;
        });

      });
  }

  updateSelectCategory(obj) {
    const that = this;
    this.form.get('category').setValue(obj.label);
    this.datasSubjectSelectMenu = [];
    if (this.subjectField) {
      this.subjectField.resetSelect();
    }
    this.form.removeControl('subject');

    if (obj.subjects) {
      obj.subjects.forEach(function (el) {
        that.form.addControl('subject', new FormControl('', Validators.required));
        that.datasSubjectSelectMenu.push(el);
      });
    }
  }

  updateSelectSubject(obj) {
    this.form.get('subject').setValue(obj);
    if (this.isMaintenanceFeatureActivated) {
      const selectedSubjectId = Number(obj.id);
      this.isAppointmentRequestSelected = selectedSubjectId === HelpConst.maintenanceType.ScheduledMaintenance || selectedSubjectId === HelpConst.maintenanceType.UnScheduledMaintenance;
    }
  }

  ngOnDestroy() {
    // Back navigator
    this.popinService.opened(false);
  }

  onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  selectClick(selectId) {
    if (selectId === 'selectCategory' && typeof (this.subjectField) !== 'undefined' && this.subjectField.showList) {
      this.subjectField.showList = false;
    } else if (selectId === 'selectSubject' && typeof (this.category) !== 'undefined' && this.category.showList) {
      this.category.showList = false;
    }
  }

  goToMaintenanceInput() {
    if (!this.isMaintenanceFeatureActivated) {
      return;
    }
    this.popinService.opened(false);
    this.helpService.setMaintenanceRequestParameters({
      maintenanceType: this.form.get('subject').value,
      fromUrl: '/feed/help-contact-us'
    });
    this.router.navigate(['/feed/help-maintenance-request']);
  }
}
