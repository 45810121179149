import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/authentication/auth.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';
import { passwordUserNameValidator } from 'app/shared/app-forms/validators/password-username.validator';
import { UserService } from 'app/shared/user/user.service';
import { sameAsValidator } from 'app/shared/app-forms/validators/same-as.validator';

@Component({
  selector: 'app-end-forgot-password',
  templateUrl: './end-forgot-password.component.html',
  styleUrls: ['./end-forgot-password.component.scss']
})
export class EndForgotPasswordComponent implements OnInit {
  public form: FormGroup;
  public submitted = false;
  public errorMessage: string;
  public tokenExpired = false;
  public token: string = undefined;
  public hidePassword = true;
  public hideConfirmPassword = true;
  public isLoading = false;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private featureService: FeatureService,
    private userManagementService: UserManagementService,
    public navigationDirection: NavigationDirectionService,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params) {
        this.token = params.key;
        this.checkTokenValidity();
      }
    });
    this.changeLanguage();
    this.form = this.formBuilder.group({
      password: ['', [CustomValidators.securePassword, CustomValidators.securePassword], [passwordUserNameValidator(this.userService, this.token)]],
      confirmPassword: ['', Validators.required],
    });
    this.form.get('confirmPassword').setValidators([CustomValidators.required, sameAsValidator(this.form.get('password'))]);
  }

  private checkTokenValidity(): void {
    this.authService.checkUserResetPasswordTokenValidity(this.token).subscribe(
      () => {
        this.tokenExpired = false;
      },
      () => {
        this.tokenExpired = true;
      },
    );
  }

  public resetConfirmationField(): void {
    this.form.controls.confirmPassword.setValue('');
    this.form.controls.confirmPassword.updateValueAndValidity();
  }

  onSubmit() {
    const password = this.form.get('password').value;
    if (this.form.valid && !this.isLoading) {
        this.isLoading = true;
        const key = this.route.snapshot.queryParams['key'];
        this.userManagementService.resetPassword(key, password).subscribe(
          () => {
            this.onSubmitSuccess();
          },
          () => {
            this.onSubmitError('global-generic_terms-generic_error');
          });
    }
  }

  public togglePassword(event) {
    event.stopPropagation();
    event.preventDefault();
    this.hidePassword = !this.hidePassword;
  }

  public toggleConfirmPassword(event) {
    event.stopPropagation();
    event.preventDefault();
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

  private changeLanguage() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['culture']) {
        this.translationService.language = queryParams['culture'];
      }
    });
  }

  private onSubmitSuccess() {
    this.isLoading = false;
    this.router.navigate(['/start/success']);
  }

  private onSubmitError(errorMessage) {
    this.isLoading = false;
    this.errorMessage = errorMessage;
  }
}
