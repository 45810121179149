import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

// ## Google Tag Manager setup
const gtmNoScript = (gtmID: string): string =>
  `<iframe src='https://www.googletagmanager.com/ns.html?id=${gtmID}'
          height='0'
          width=0'
          style='display:none;visibility:hidden'>
  </iframe>`;

const noscript = document.createElement('noscript');
noscript.innerHTML = gtmNoScript(environment.gtmKey);
document.body.appendChild(noscript);
// ## end gtm setup

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);


