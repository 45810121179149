import { Injectable } from '@angular/core';
import { AuthStorageService } from '../storage/auth/auth-storage.service';
import { JwtHelper } from 'angular2-jwt';
import { UserManagementConst } from './userManagement.const';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpService } from '../helper/http.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserService {
  public userId = null;
  public culture = null;
  public roles: string[] = [];
  private user: any;
  private keyPrefix = 'http://www.aldautomotive.fr/claims/';
  public isCheckingPasswordUsername: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private storage: AuthStorageService,
              private httpClient: HttpService,
              private jwtHelper: JwtHelper) {
  }
  public validatePasswordAuthenticated(password: string): Observable<boolean> {
   const url =  UserManagementConst.api.endpoints.validatePasswordAuthenticated;
    return this.httpClient.post(url, `"${password}"`);
  }

  public validatePassword(resetPasswordToken: string, password: string): Observable<boolean> {
    const body = { resetPasswordToken, password };
    return this.httpClient.post<any>(UserManagementConst.api.endpoints.validatePassword, body, true);
}

  public getActivedirectoryid() {
    return this.getValueByKey('userid');
  }

  public getCountry(): string {
    return this.getValueByKey('country');
  }

  public getClientId() {
    return this.getValueByKey('client');
  }

  public getRoles() {
    const roles = this.roles;
    return roles.map(role => role.toLowerCase());
  }

  public getTokenRole(): string {
    return this.getValueByKey('role', false);
  }

  private getValueByKey(key: string, prefix = true) {
    if (this.storage.auth) {
      this.user = this.getUserInfoFromJwt();
    }
    return this.user && this.user[(prefix ? this.keyPrefix : '') + key] ? this.user[(prefix ? this.keyPrefix : '') + key] : null;
  }

  private getUserInfoFromJwt(): any {
    return this.jwtHelper.decodeToken(this.storage.auth['access_token']);
  }

  public getExternalAppToken(carPlate: string): Observable<string> {
    const url = `${UserManagementConst.api.endpoints.getExternalJwtToken}?carPlate=${carPlate}`;
    return this.httpClient.getString(url);
  }

  public isPasswordExpired(email: string): Observable<boolean> {
    const url = new URL(UserManagementConst.api.endpoints.isPasswordExpired);
    url.searchParams.append('email', email);
    return this.httpClient.getString(url.href, true).pipe(
      map(status => status ? JSON.parse(status) : false),
      catchError(() => of(false)),
    );
  }
}
