import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

export function validateCustomEmail(control: AbstractControl): ValidationErrors | null {
  /* tslint:disable:max-line-length */
  const emailRegex = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9*](?:[a-zA-Z0-9-*]*[a-zA-Z0-9*])?\.)+[a-zA-Z0-9*](?:[a-zA-Z0-9-*]*[a-zA-Z0-9*])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return emailRegex.test(control.value) ? null : { email: true };
}

@Directive({
  selector: '[customEmail]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CustomEmailValidatorDirective, multi: true }],
})
export class CustomEmailValidatorDirective implements Validator {
  validate: ValidatorFn = validateCustomEmail;
}
