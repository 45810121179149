import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PopinService } from 'app/shared/ui/popin.service';
import { HelpService } from 'app/help/shared/help.service';
import { DriverService } from 'app/driver/shared/driver.service';
import { UserProfile } from 'app/driver/shared/models/user-profile.model';
import { UserRoleContract } from 'app/driver/shared/models/user-contract.model';
import { contractIsStatusOfType } from 'app/driver/shared/contract/contract-status.helper';
import { ContractConsolidatedStatusType } from 'app/driver/shared/contract/contract-status.const';
import { ContractAskPermission } from 'app/help/shared/models/help-driver-assistance.model';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';

@Component({
  selector: 'app-take-abroad',
  templateUrl: './take-abroad.component.html',
  styleUrls: ['./take-abroad.component.scss']
})
export class HelpTakeAbroadComponent implements OnInit, OnDestroy {

  @ViewChild('selectAutocomplete')
  public selectAutocomplete;

  @ViewChild('selectAutocompleteCountry')
  public selectAutocompleteCountry;

  public addressSelected: string;
  public error = null;
  public countrySelected: string;
  public form: FormGroup;
  public newUserProfile: UserProfile;
  public vehicleList = [];
  public vehicleSelected = 0;
  public submitStatus = 'initial';

  public minDate = new Date();

  constructor(
    private router: Router,
    private popinService: PopinService,
    private helpService: HelpService,
    private driverService: DriverService,
    private gtmService: GtmService) {
  }

  ngOnInit() {
    this.getDriver();
    this.popinService.opened(true);
  }

  private getDriver(): void {
    this.driverService.newDriverStream
      .subscribe((newDriver: UserProfile) => {
        this.newUserProfile = newDriver;
        this.generateVehicleList();
        this.buildForm();
      });
  }

  public updateVehicleList(obj): void {
    this.form.get('contractId').setValue(obj.id);
  }

  public updateSelectAddress(label) {
    this.form.get('deliveryAddress').setValue(label);
  }

  public updateSelectCountry(label) {
    this.form.get('destination').setValue(label);
  }

  private generateVehicleList(): void {
    this.vehicleList = this.driverService.getNewUserRole(this.newUserProfile).contracts.filter((contract: UserRoleContract) => {
      return contractIsStatusOfType(contract, ContractConsolidatedStatusType.Active);
    }).map((contract: UserRoleContract) => {
      return {
        id: contract.reference,
        label: `${contract.vehicle.brand} ${contract.vehicle.plate}`
      };
    });

    if (this.vehicleList.length === 1) {
      this.vehicleSelected = 0;
    }
  }

  private buildForm(): void {
    this.form = new FormGroup({
      contractId: new FormControl('', [Validators.required]),
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      destination: new FormControl('', [Validators.required]),
      deliveryAddress: new FormControl('', [Validators.required]),
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.submitStatus = 'pending';
      const askPermissionForm = Object.assign({}, this.form.value) as ContractAskPermission;
      this.helpService.askPermissionToTakeAbroad(askPermissionForm).subscribe(() => {
        this.submitStatus = 'success';
        this.gtmService.sendEvent(GTM_EVENTS.askPermissionToTakeAbroad);
      }, () => {
        this.submitStatus = 'initial';
        this.error = 500;
      })
    }
  }

  public onBackClick() {
    this.popinService.opened(false);
    this.router.navigate(['feed/help']);
  }

  ngOnDestroy() {
  }
}
