import { environment } from 'environments/environment';

export const FeatureConst = {
  features: {
    GloveBox: 'spec_isGloveBoxActivated',
    DeclareDamage: 'spec_isDeclareDamageActivated',
    OnRoadAssistance: 'spec_isOnRoadAssistanceActivated',
    Euromaster: 'spec_isEuroMasterLinkActivated',
    TrafficFinesV1: 'spec_isTrafficFinesActivated',
    TrafficFinesV2: 'spec_isTrafficFinesV2Activated',
    Quoter: 'spec_isQuoterActivated',
    AldQuote: 'spec_isAldQuoteActivated',
    AldQuoteV2: 'spec_isAldQuoteV2Activated',
    CarSelector: 'spec_isCarSelectorActivated',
    NoQuoter: 'spec_isNoQuoterSelected',
    SalesForce: 'spec_salesForceActivated',
    SmartCare: 'spec_isSmartCareActivated',
    MultiReturnDocument: 'spec_isMultiReturnDocumentActivated',
    MaintenanceRequest: 'spec_isHelpMaintenanceRequestActivated',
    MaintenancePosCategory: 'spec_isMaintenanceCategoryActivated',
    AssistanceThirdNumber: 'spec_isAssistanceThirdNumberActivated',
    MaintenanceHistory: 'spec_isMaintenanceHistoryActivated',
    EditMyProfileActivated: 'spec_isEditOwnProfileActivated',
    EditOwnNameActivated: 'spec_isEditOwnNameActivated',
    ShowCarReturnFirstStep: 'spec_showCarReturnFirstStep',
    sharedDocuments: 'spec_isSharedDocumentsActivated',
    AssistanceForDriver: 'spec_isDriverAssistanceOnDriverAppActivated',
    FuelCard: 'spec_isFuelCardActivated',
    AldCamera: 'spec_isAldCamera',
    CarRestitutionHtmlOnly: 'spec_isCarRestitutionHtmlOnly',
    SingleCarRepairNumber: 'spec_hasSingleCarRepairNumber',
    HelpContactUsSection: 'spec_isHelpContactUsActivated',
    HelpMapSection: 'spec_isHelpMapActivated',
    HelpCustomerServiceSection: 'spec_isHelpCustomerServiceActivated',
    MileageUpdate: 'spec_isMileageUpdateActivated',
    EuromasterLink: 'spec_isEuroMasterLinkActivated',
    PorscheNordLink: 'spec_isPorscheNordLinkActivated',
    aldNetDriverReportsWithTokenActivated: 'spec_isALDNetDriverReportWithTokenActivated',
    ChatBotLink: 'spec_isChatBotLinkActivated',
  },
  api: {
    availableFeatures: environment.apigatewayUrl + '/apigateway/Configuration/api/Feature/',
    anonymousAvailableFeatures: environment.apigatewayUrl + '/apigateway/Configuration/api/Feature/'
  },
  routes: {
    'help-damage-declaration': 'spec_isDeclareDamageActivated',
    'help-case-request': 'spec_salesForceActivated',
    'help-follow-requests': 'spec_salesForceActivated',
    'help-contact-us': '!spec_salesForceActivated',
    'help-maintenance-request': 'spec_isHelpMaintenanceRequestActivated',
    'smart-care': 'spec_isSmartCareActivated',
    'pos': '!spec_isSmartCareActivated',
    'maintenance-history': 'spec_isMaintenanceHistoryActivated',
    'edit': 'spec_isSharedDocumentsActivated',
    'help-order-damage-repair': 'spec_isDriverAssistanceOnDriverAppActivated',
    'help-order-maintenance': 'spec_isDriverAssistanceOnDriverAppActivated',
    'help-take-abroad': 'spec_isDriverAssistanceOnDriverAppActivated',
    'fuel-history': 'spec_isFuelCardActivated',
    'quotation': 'spec_isQuoterActivated',
    'mileage': 'spec_isMileageUpdateActivated',
    'ald-camera' : 'spec_isAldCamera',
  }
};
