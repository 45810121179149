import { Vehicle } from '../../driver/shared/models/vehicle.model';
import { Injectable } from '@angular/core';
import { HelpConst } from './help.const';
import { UserService } from '../../shared/user/user.service';
import { DriverService } from '../../driver/shared/driver.service';
import { Driver } from '../../driver/shared/models/driver.model';
import { ApiService } from '../../shared/common/api.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { typeSourceSpan } from '@angular/compiler';
import { ContactRequest } from './models/create-case-request.model';
import { DeclareDamageRequest } from './models/declare-damage-request.model';
import { SendMaintenanceRequest } from './models/maintenance-request.model';
import { ContractAskPermission, ContractOrderDamageRepair, ContractOrderMaintenance } from './models/help-driver-assistance.model';
import { DocumentCategoryModel } from 'app/documents/shared/models/document-category.model';
import { DocumentsConst } from 'app/documents/shared/documents.const';
import { DocumentSharedWithUsersModelLight } from 'app/documents/shared/models/document-shared-with-users-light.model';
import { DocumentsService } from 'app/documents/shared/documents.service';
import { catchError, map } from 'rxjs/operators';
import { DocumentsCategory } from 'app/documents/shared/enums/documents-category';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { HttpService } from 'app/shared/helper/http.service';

@Injectable()
export class HelpService {

  private driver: Driver;
  private maintenanceRequestParameters: any;
  public documents$: BehaviorSubject<Document[]> = new BehaviorSubject(null);
  constructor(private api: ApiService,
    private userService: UserService,
    private driverService: DriverService,
    private translationService: TranslationService,
    private documentsService: DocumentsService,
    private gtmService: GtmService,
    private snackBarService: SnackbarService,
    private httpService: HttpService) {
  }

  public declareDamage(declareDamageParams: DeclareDamageRequest) {
    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.declareDamage, declareDamageParams)
      .map(response => {
        return response;
      });
  }

  public uploadDocumentAction(formData): Observable<any> {
      const documentsModule = DocumentsConst.api.modules.documents;
      const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.CreateDocument;
      return this.httpService.postFile(url, formData);
  }

  public deleteDocument(documentId: any[]): Observable<void> {
    const body = documentId;
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.DeleteDocuments;
    return this.httpService.post(url, body);
  }

  public contactAld(contactAldParams: ContactRequest) {
    contactAldParams.culture = this.translationService.language;
    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.sendRequest, contactAldParams)
      .map(response => {
        return response;
      });
  }

  public sendMaintenanceRequest(maintenanceRequestParams: SendMaintenanceRequest): Observable<any> {
    maintenanceRequestParams.culture = this.translationService.language;

    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.maintenanceRequest, maintenanceRequestParams)
      .map(response => {
        return response;
      });

  }

  public createCaseRequest(newCaseParams: ContactRequest): Observable<any> {
    newCaseParams.culture = this.translationService.language;
    return this.api.post(HelpConst.api.base + HelpConst.api.endpoints.sendRequest, newCaseParams)
      .map(response => {
        return response;
      });
  }

  public getCategories() {
    const params = this._getQueryStringUrl({ subsidiaryCode: this.userService.getCountry() });
    return this.api.get(HelpConst.api.base + HelpConst.api.endpoints.getContactCategories + '?' + params);
  }

  public getCaseHistory() {
    return this.api.get(HelpConst.api.base + HelpConst.api.endpoints.getCaseHistory);
  }

  public setMaintenanceRequestParameters(param): void {
    this.maintenanceRequestParameters = param;
  }

  public getMaintenanceRequestParameters(): any {
    return this.maintenanceRequestParameters;
  }

  public getDefaultVehicle(): Vehicle {
    let defaultVehicle: Vehicle = null;
    this.driverService.driverStream.subscribe(driver => {
      this.driver = driver;
      defaultVehicle = driver.contracts[0].vehicle;
    });
    return defaultVehicle;
  }

  private _getQueryStringUrl(params: any): string {
    return Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
  }

  public askPermissionToTakeAbroad(askPermissionForm: ContractAskPermission) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendAskPermissionForm;
    return this.api.post(url, askPermissionForm);
  }

  public orderDamageRepair(damageRepairForm: ContractOrderDamageRepair) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendOrderDamageRepairRequest;
    return this.api.post(url, damageRepairForm);
  }

  public orderMaintenance(orderMaintenanceForm: ContractOrderMaintenance) {
    const url = HelpConst.api.base + HelpConst.api.endpoints.sendOrderMaintenanceRequest;
    return this.api.post(url, orderMaintenanceForm);
  }

  public getAvailableCategories(): Observable<DocumentCategoryModel[]> {
    const documentsModule = DocumentsConst.api.modules.documents;
    const url = DocumentsConst.api.baseUrl + documentsModule.url + documentsModule.endpoints.GetCategories;
    return this.api.get(url);
  }

}
