import { DocumentsEffects } from '../shared/documents.effects';
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Document } from '../shared/models/document.model';
import { WindowRef } from 'app/shared/helper/window.ref';
import { DocumentsActionsIcon } from '../shared/enums/documents-actions-icon';
import { finalize, tap } from 'rxjs/operators';
import { fadeAnimation } from 'app/shared/animations/fade.animations';
import { Router } from '@angular/router';
import { APP_ROUTES } from 'app/app.routes';
import { DocumentsConst } from '../shared/documents.const';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { FeatureService } from 'app/shared/feature/feature.service';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss'],
  animations: [fadeAnimation],
})
export class DocumentItemComponent implements OnInit {
  @Input() document: Document;
  @Input() isDriverDocument: boolean;

  public readonly ACTIONS_ICONS = DocumentsActionsIcon;
  public documentModule = DocumentsConst.name;
  public isDocumentDeleting: boolean;
  public isActivatedShareDocumentsFeature = false;

  constructor(
    private documentsEffects: DocumentsEffects,
    private router: Router,
    private featureService: FeatureService,
    private gtmService: GtmService,
    private windowRef: WindowRef) {
    }

  ngOnInit(): void {
    this.featureService.isFeatureActivated(FeatureConst.features.sharedDocuments).pipe(
      tap((isActivated: boolean) => this.isActivatedShareDocumentsFeature = isActivated)
    ).subscribe();
  }

  public deleteDocument(): void {
    this.isDocumentDeleting = true;
    this.documentsEffects.deleteDocumentAction(this.document.id).pipe(
      finalize(() => this.isDocumentDeleting = false)
    ).subscribe();
  }

  public downloadDocument(): void {
    this.documentsEffects.downloadDocumentAction(this.document.id).subscribe(documentUrl => {
          this.windowRef.nativeWindow.open(documentUrl, '_blank');
          this.gtmService.sendEvent(GTM_EVENTS.downloadDocument, 'download document');
      });
  }

  public editDocument(): void {
    this.router.navigate([APP_ROUTES.DOCUMENTS, 'edit', this.document.id]);
  }
}
