import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../shared/authentication/auth.service';
import { AppConfigService } from '../../shared/config/app-config.service';
import { TranslationService } from '../../shared/translation/translation.service';
import { AuthConst } from '../../shared/authentication/auth.const';
import { CustomValidators } from 'app/shared/app-forms/validators/custom.validator';
import { passwordUserNameValidator } from 'app/shared/app-forms/validators/password-username.validator';
import { UserService } from 'app/shared/user/user.service';

@Component({
  selector: 'app-prospect-create-password',
  templateUrl: './prospect-create-password.component.html',
  styleUrls: ['./prospect-create-password.component.scss']
})
export class ProspectCreatePasswordComponent implements OnInit {

  public isDesktop = false;
  public form: FormGroup;
  public submitStatus = 'initial';
  public submitted = false;
  public errorMessage: string;
  public passwordInputType = 'password';
  public confirmPasswordInputType = 'password';
  public token: string;
  public tokenExpired: boolean;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private userService: UserService) {

  }

  @HostListener('window:resize', ['$event'])
  onResize($event = null) {
    this.isDesktop = (window.innerWidth > this.appConfig.config.breakpoints.m);
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams['key'];
    this.checkTokenValidity();
    this.form = this.formBuilder.group({
      password: [, [Validators.required, CustomValidators.securePassword], [passwordUserNameValidator(this.userService, this.token)]],
      confirmPassword: [, Validators.required],
    });
    this.onResize();
    this.changeLanguage();
  }

  private checkTokenValidity(): void {
    this.authService.checkProspectPendingCreationTokenValidity(this.token).subscribe(
      () => {
        this.tokenExpired = false;
      },
      () => {
        this.tokenExpired = true;
      },
    );
  }

  onSubmit() {
    const password = this.form.get('password').value;
    const confirmPassword = this.form.get('confirmPassword').value;
    if (this.form.valid && this.submitStatus !== 'pending' && (password === confirmPassword)) {
      this.submitStatus = 'pending';
      this.authService.endProspectCreatePassword(password).subscribe(
        result => {
          this.submitStatus = 'success';
          this.router.navigate(['/start/success']);
        },
        error => {
          this.submitStatus = 'initial';
          if (error.status === '400') {
            this.errorMessage = 'global-generic_terms-incorrect_input';
          } else {
            this.errorMessage = 'global-generic_terms-generic_error';
          }
        });
    }
  }

  private changeLanguage() {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['culture']) {
        this.translationService.language = queryParams['culture'];
      }
    });
  }

  public resetConfirmationField(): void {
    this.form.controls.confirmPassword.setValue('');
    this.form.controls.confirmPassword.updateValueAndValidity();
  }
  backToLogin() {
    this.router.navigate(['/start/login']);
  }
}
