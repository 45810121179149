import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopinService } from '../../shared/ui/popin.service';
import { ConfigurationService } from '../../shared/config/configuration.service';
import { AppStorageService } from '../../shared/storage/common/app-storage.service';
import { GTM_EVENTS } from '../../shared/gtm/gtm-events.const';
import { combineLatest } from 'rxjs';
import { ConfigurationModel } from '../../shared/config/configuration.model';

@Component({
  selector: 'app-help-generic',
  templateUrl: './help-generic.component.html',
  styleUrls: ['./help-generic.component.scss']
})
export class HelpGenericComponent implements OnInit, OnDestroy {

  public bookingServiceNumberLocal: string;
  public bookingServiceNumberInternational: string;
  public readonly GTM_EVENTS = GTM_EVENTS;

  constructor(
    private popinService: PopinService,
    private appStorageService: AppStorageService,
    private configurationService: ConfigurationService) {
  }

  ngOnInit() {
    this.popinService.opened(true);

    combineLatest(
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'BookingServiceNumberLocal']),
    this.configurationService.getConfItems([this.appStorageService.countrySelected, 'BookingServiceNumberInternational']),
    ).subscribe(
      (feedback: ConfigurationModel[]) => {
        if (feedback[0]) {
          this.bookingServiceNumberLocal = feedback[0].value;
        }
        if (feedback[1]) {
          this.bookingServiceNumberInternational = feedback[1].value;
        }
      }, e => {
        this.bookingServiceNumberLocal = 'error occured';
        this.bookingServiceNumberInternational = 'error occured';
      });
  }

  ngOnDestroy() {
    this.popinService.opened(false);
  }

  onBackClick() {
    this.popinService.opened(false);
  }

}
