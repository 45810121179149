﻿import { ApplicationRef, Component, HostBinding, OnDestroy, OnInit, DoCheck, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationDirectionService } from '../../shared/router-transitions/navigation-direction.service';
import { generateTransitions } from '../../shared/router-transitions/router-transitions.animations';
import { AuthService } from '../../shared/authentication/auth.service';
import { Subscription } from 'rxjs';
import { FeatureService } from 'app/shared/feature/feature.service';
import { FeatureConst } from 'app/shared/feature/feature.consts';
import { UserManagementService } from 'app/shared/user/userManagement.service';
import { finalize } from 'rxjs/operators';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    generateTransitions()
  ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {

  public form: FormGroup;
  public isLoading: boolean;
  public submitted = false;
  public resetErrorMessage: string;
  public captchaCookieConsentStatus: string;
  private interval;

  @HostBinding('@routeAnim')
  public routeAnimState: string;
  private navDirectionChangeSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
    public router: Router,
    private authService: AuthService,
    public navigationDirection: NavigationDirectionService,
    private appRef: ApplicationRef,
    private featureService: FeatureService,
    private gtmService: GtmService,
    private changeDetectorRef: ChangeDetectorRef,
    private userManagementService: UserManagementService) {
    this.routeAnimState = this.navigationDirection.direction;
    this.navDirectionChangeSubscription = navigationDirection.changeObservable.subscribe(direction => {
      this.routeAnimState = direction;
      this.appRef.tick();
    });

  }

  ngDoCheck() {
    this.captchaCookieConsentStatus = window.sessionStorage.getItem('captcha_cookie_consent_status');
    if (this.captchaCookieConsentStatus === 'agree') {
      clearInterval(this.interval);
    }
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    this.interval = setInterval(() => { }, 1000);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userid: [this.userManagementService.loginEmail],
      recaptcha: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.navDirectionChangeSubscription.unsubscribe();
    window.sessionStorage.removeItem('captcha_cookie_consent_status');
    clearInterval(this.interval);
  }

  onSubmit() {
    if (this.form.valid) {

      this.isLoading = true;
      const email = this.form.get('userid').value;
      const recaptcha = this.form.get('recaptcha').value;
        this.userManagementService.forgotPassword(email, recaptcha).pipe(
          finalize(() => this.userManagementService.loginEmail = null),
        ).subscribe(result => {
          this.onSubmitSuccess();
          this.gtmService.sendEvent(GTM_EVENTS.forgetPassword, 'password change');
        },
          (error) => {
            this.onSubmitError(error.error.title);
          });
    }
  }

  private onSubmitSuccess() {
    this.isLoading = false;
    this.router.navigate(['/start/forgot-password-confirmation']);
  }

  private onSubmitError(errorMessage) {
    this.isLoading = false;
    this.resetErrorMessage = errorMessage;
  }
}
