import { EventEmitter, Injectable } from '@angular/core';
import { SupportStorage } from '../support-storage.model';

enum STORES {
  SESSION,
  LOCAL
}

@Injectable()
export class AppStorageService {

  public static STORES = STORES;
  private supportStorage: SupportStorage;
  public SELECTED_COUNTRY = 'country_selected';
  public CURRENT_ROLE = 'currentRole';
  $localeChanges = new EventEmitter<any>();
  constructor() {
    this.supportStorage = new SupportStorage();
  }

  get countrySelected(): string {
    return this.get(this.SELECTED_COUNTRY, STORES.SESSION);
  }

  set countrySelected(countrySelected: string) {
    this.set(this.SELECTED_COUNTRY, countrySelected, STORES.SESSION);
  }

  get currentUserRole(): string {
    return this.get(this.CURRENT_ROLE, STORES.SESSION);
  }

  set currentUserRole(countrySelected: string) {
    this.set(this.CURRENT_ROLE, countrySelected, STORES.SESSION);
  }

  set(key: string, value: any, store = AppStorageService.STORES.LOCAL): boolean | Promise<boolean> {
    const storage = this._getStore(store);

    if (typeof value === 'object') {
      value = this._stringify(value);
    }
    storage.setItem(key, value);
    this.$localeChanges.emit({ key, value });
    return true;
  }

  remove(key: string, store = AppStorageService.STORES.LOCAL): boolean | Promise<boolean> {
    const storage = this._getStore(store);
    storage.removeItem(key);
    return true;
  }

  get(key: string, store = AppStorageService.STORES.LOCAL, defaultValue: any = false, nestedKey?): any | Promise<any> {
    const storage = this._getStore(store);

    if (storage.getItem(key)) {
      try {
        let datas = JSON.parse(storage.getItem(key));
        if (nestedKey) {
          datas = datas.hasOwnProperty(nestedKey) ? datas[nestedKey] : defaultValue;
        }
        return datas;
      } catch (e) {
        return storage.getItem(key);
      }
    } else {
      return defaultValue;
    }
  }

  private _getStore(storage): Storage {
    let store;

    if (storage === AppStorageService.STORES.SESSION && this._isAvailableStorage(window.sessionStorage)) {
      store = window.sessionStorage;
    } else if (storage === AppStorageService.STORES.LOCAL && this._isAvailableStorage(window.localStorage)) {
      store = window.localStorage;
    } else {
      store = this.supportStorage;
    }

    return store;
  }

  private _isAvailableStorage(storage: Storage): boolean {
    const test = 'test';
    try {
      storage.setItem(test, test);
      storage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  private _stringify(value) {
    return JSON.stringify(value);
  }

}
