import { ValidationErrors, AbstractControl, ValidatorFn } from '@angular/forms';

export function sameAsValidator(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control && otherControl) {
      if (control.value !== otherControl.value) {
        return { confirmedPassword: true };
      }
    }
    return null;
  };
}
