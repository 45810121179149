import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { FeatureService } from '../../feature/feature.service';
import { combineLatest } from 'rxjs';

@Directive({
  selector: '[areFeaturesActivated]',
})
export class AreFeaturesActivatedDirective implements OnInit, OnDestroy {

  @Input() areFeaturesActivated: string[];
  // and : all features has to be activated, or: at least one should be activated
  @Input() areFeaturesActivatedOperator: 'or' | 'and' = 'and';

  private directiveActive = true;
  private isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private featureService: FeatureService,
  ) {
  }

  ngOnInit(): void {
    const featureRequests = this.areFeaturesActivated.map((featureName) => {
      if (!this.featureService.isFeatureExists(featureName)) {
        console.error(`feature ${featureName} does not exists`);
        return;
      }
      return this.featureService.isFeatureActivated(featureName);
    });

    combineLatest(featureRequests)
      .pipe(takeWhile(() => this.directiveActive))
      .subscribe((isFeaturesActivated: boolean[]) => {

        if (!isFeaturesActivated) {
          this.viewContainerRef.clear();
          return;
        }
        const operatorCondition = this.areFeaturesActivatedOperator === 'and' ? isFeaturesActivated.every(f => f) : isFeaturesActivated.some(f => f);
        if (operatorCondition) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
  }

  ngOnDestroy(): void {
    this.directiveActive = false;
  }
}
