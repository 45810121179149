import { DidomiCookieConsentViewModel } from './didomi-cookies.model';

export const DidomiCookieConfigurations: DidomiCookieConsentViewModel = {
    app: {
        apiKey: '',
        privacyPolicyURL: '',
    },
    didomiVendorSdkIds: [
        'googleana',
        'recaptcha',
    ],
    gaVendorId: '',
    gtmKey: '',
    tagManager: {
        provider: 'gtm',
    },
    theme: {
        buttons: {
            regularButtons: {
                backgroundColor: '#050b7f',
                borderRadius: '30px',
                color: 'white',
            },
            highlightButtons: {
                backgroundColor: '#050b7f',
                borderRadius: '30px',
                color: 'white',
            },
        },
    },
};
