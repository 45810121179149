export const APP_ROUTES = {
  NOT_FOUND: 'not-found',
  NO_CONTRACTS: 'no-contract',
  USER_ERROR: 'user-error',
  // ## Anonymous Routes
  START: 'start',
  LOGIN: 'login',
  // ## FEED Routes
  FEED: 'feed',
  // ## CARS Routes
  CARS: 'cars',
  CARS_INFOS: 'infos',
  CARS_CONTRACT: 'contract',
  CARS_RESTITUTION: 'restitution',
  CARS_MILEAGE: 'mileage',
  // ## DOCUMENTS Routes
  DOCUMENTS: 'documents',
  DOCUMENTS_ADD: 'add-other',
  // ## SERVICES Routes
  SERVICES: 'smart-care',
  POS: 'pos',
  // ## MORE Routes
  MORE: 'more',
  // ## GENERAL Routes
  ASSISTANCE: 'help',
  PERSONAL_INFOS: 'personal-informations',
};
