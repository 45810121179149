import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Document } from './models/document.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, finalize, catchError, filter, tap } from 'rxjs/operators';
import { DocumentSharedWithUsersModelLight } from './models/document-shared-with-users-light.model';
import { SnackbarService } from 'app/shared/snackbar/snackbar.service';
import { DocumentsService } from './documents.service';
import { GtmService } from 'app/shared/gtm/gtm.service';
import { GTM_EVENTS } from 'app/shared/gtm/gtm-events.const';
import { DocumentsCategory } from './enums/documents-category';
import { EditDocumentDto } from './models/edit-document-dto.models';

@Injectable({
  providedIn: 'root'
})
export class DocumentsEffects {

  public documents$: BehaviorSubject<Document[]> = new BehaviorSubject(null);
  public isDocumentsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public hasDocumentsError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public searchQuery$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private documentsService: DocumentsService,
    private gtmService: GtmService,
    private snackBarService: SnackbarService) {
  }

  public getDocumentsAction(): Observable<Document[]> {
    return this.documentsService.getDocuments().pipe(
      map((documents: Document[]) => {
        this.documents$.next(documents);
        return documents;
      }),
      finalize(() => {
        this.isDocumentsLoading$.next(false);
      }),
      catchError((error) => {
        this.hasDocumentsError$.next(error);
        return of(error);
      })
    );
  }

  public downloadDocumentAction(documentID: string): Observable<void> {
    return this.documentsService.getDocumentById(documentID).pipe(
      filter(documentUrl => !!documentUrl),
      map((documentUrl) => {
        this.gtmService.sendEvent(GTM_EVENTS.downloadDocument, 'download document');
        return documentUrl;
      }),
      catchError((error) => {
        this.snackBarService.error('documents-documents_list-download_document_snackbar_error');
        return of(error);
      })
    );
  }

  public uploadDocumentAction(formData, documentSharedModelWith: DocumentSharedWithUsersModelLight[]): Observable<any> {
    return this.documentsService.uploadDocument(formData).pipe(
      map((id: string) => {
        const document: Document = {
          id: id,
          creationDate: new Date().toISOString(),
          category: {
            name: DocumentsCategory.PERSONAL_DOCUMENT,
          },
          title: JSON.parse(formData.get('fileData')).title,
          comment: JSON.parse(formData.get('fileData')).comment,
          type: formData.get('file').type,
          fileName: formData.get('file').name,
          users: documentSharedModelWith
        } as unknown as Document;
        const currentDocument = this.documents$.getValue();
        this.documents$.next(currentDocument ? [...[document], ...currentDocument] : [document]);
        this.gtmService.sendEvent(GTM_EVENTS.uploadDocument);
        this.snackBarService.success('documents-documents_list-upload_document_snackbar_success');
        return id;
      }),
      catchError((error) => {
        this.snackBarService.error('documents-documents_list-upload_document_snackbar_error');
        return of(error);
      }));
  }

  public editDocumentAction(documentID: string, document: EditDocumentDto, sharedWith: DocumentSharedWithUsersModelLight[]): Observable<void> {
    return this.documentsService.editDocument(document).pipe(
      tap(() => {
        const documents = [...this.documents$.getValue()];
        const updatedDocumentList: Document[] = documents.map((value) => {
          if (value.id === documentID) {
            value.users = sharedWith;
            value.title = document.title;
            value.comment = document.comment;
          }
          return value;
        });
        this.documents$.next([...updatedDocumentList]);
        this.snackBarService.success('documents-documents_list-edit_document_snackbar_success');
      }),
      catchError((error) => {
        this.snackBarService.error('documents-documents_list-edit_document_snackbar_error');
        return of(error);
      }));
  }

  public deleteDocumentAction(documentId: string | number): Observable<void> {
    this.snackBarService.loading('documents-documents_list-delete_document_snackbar_loading');
    return this.documentsService.deleteDocument(documentId).pipe(
      map(() => {
        this.snackBarService.success('documents-documents_list-delete_document_snackbar_success');
        this.documents$.next(this.documents$.getValue().filter(d => d.id !== documentId));
      }),
      catchError((error) => {
        this.snackBarService.error('documents-documents_list-delete_document_snackbar_error');
        return of(error);
      })
    )
  }
}
